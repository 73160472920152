import { FC } from "react";
import { Container, Grid } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import MenuListTable from "components/menu/ui/menuList/menuListTable";

interface MenuListPageProps {}

const MenuListPage: FC<MenuListPageProps> = () => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Container>
      <BottomSpacedPageHeader>Menus</BottomSpacedPageHeader>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MenuListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenuListPage;
