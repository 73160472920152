import { Ingredient } from "client/blueChef";
import { FoodItem } from "client/jspPlatformExperiment";

// eslint-disable-next-line import/prefer-default-export
export const getFoodItemFromBCIngredient = (ingData: Ingredient): FoodItem => {
  return {
    id: ingData.id,
    food_name: ingData.name,
    state: ingData.state,
    category: ingData.category,
    dispense_category: ingData.dispense_category,
    storage_temp: ingData.storage_temp,
    food_nutrition_records: ingData.nutrition_records
      ? [
          {
            food_id: ingData.id,
            data_source: ingData.nutrition_records[0].data_source,
            serving_size: ingData.nutrition_records[0].serving_size,
            calories: ingData.nutrition_records[0].calories,
            fat: ingData.nutrition_records[0].fat,
            saturated_fat: ingData.nutrition_records[0].saturated_fat,
            trans_fat: ingData.nutrition_records[0].trans_fat,
            cholesterol: ingData.nutrition_records[0].cholesterol,
            sodium: ingData.nutrition_records[0].sodium,
            carbohydrates: ingData.nutrition_records[0].carbohydrates,
            fibre: ingData.nutrition_records[0].fibre,
            sugars: ingData.nutrition_records[0].sugars,
            protein: ingData.nutrition_records[0].protein,
            calcium: ingData.nutrition_records[0].calcium,
            iron: ingData.nutrition_records[0].iron,
            potassium: ingData.nutrition_records[0].potassium,
          },
        ]
      : [],
    food_suppliers: [
      {
        food_id: ingData.id,
        supplier_id: ingData.supplier_id ?? "",
        product_code: ingData.product_code ?? "",
        product_name: ingData.product_name ?? "",
        min_format: ingData.min_format,
        unit_size: ingData.unit_size,
        unit_price: ingData.unit_price,
        retail_price: ingData.retail_price,
        barcode: ingData.barcode,
        url: ingData.url,
      },
    ], // Array<FoodItemSupplierInfo>;
  };
};
