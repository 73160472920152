import { MenuSectionBasicWithItems } from "client/jspPlatformExperiment";
import React, { FC } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Icon from "@mui/material/Icon";
import { Stack } from "@mui/material";

const StyledMenuSectionBox = styled(Box)`
  padding: 1rem 0.5rem 0 0.5rem;
`;

const MenuSectionHeader = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem 0 0.5rem;
  color: rgb(120, 203, 255);
  border-bottom: 0.1rem solid rgb(120, 203, 255);
`;

const FoodIDLabel = styled(Typography)`
  text-align: center;
  min-width: 20px;
  font-size: 0.6rem;
  font-weight: 500;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  color: white;
  background-color: rgb(220, 220, 220);
  border-radius: 1rem;
`;

const StyledMenuMainItem = styled(Box)`
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

const StyledMenuSideItem = styled(Box)`
  display: flex;
  padding-left: 1rem;
`;

const StyledItemIcon = styled(Icon)`
  color: rgb(200, 200, 200);
`;

interface MenuSectionViewBoxProps {
  section: MenuSectionBasicWithItems;
}

const MenuSectionViewBox: FC<MenuSectionViewBoxProps> = ({ section }) => {
  // display menu section
  return (
    <StyledMenuSectionBox>
      <MenuSectionHeader>{section.name}</MenuSectionHeader>
      {section.items.map((item) => (
        <StyledMenuMainItem key={item.id}>
          <Stack alignItems="center" direction="row">
            <StyledItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </StyledItemIcon>
            {item.display_name}
            <FoodIDLabel>Food ID: {item.food_id}</FoodIDLabel>
          </Stack>
          {item.sides?.map((side) => (
            <StyledMenuSideItem key={side.id}>
              <Stack alignItems="center" direction="row">
                <StyledItemIcon>
                  <SubdirectoryArrowRightIcon fontSize="small" />
                </StyledItemIcon>
                {side.display_name}
                <FoodIDLabel>Food ID: {side.food_id}</FoodIDLabel>
              </Stack>
            </StyledMenuSideItem>
          ))}
        </StyledMenuMainItem>
      ))}
    </StyledMenuSectionBox>
  );
};

export default MenuSectionViewBox;
