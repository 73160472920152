import { ChangeEvent, FC, KeyboardEvent, useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "models/nav";
import useAuth from "hooks/useAuth";
import CommonButton from "../../common/ui/CommonButton";

interface LoginContainerProps {}

const LoginPageContainer = styled(Container)`
  //padding: auto 20px;
  display: grid;
  align-content: center;
`;

const LoginTitle = styled.h1`
  font-size: 50pt;
  text-align: center;
  color: #78cbff;
`;

const LoginTextField = styled(TextField)`
  margin: 6px auto;
  min-width: 350px;
  cursor: pointer;
`;

const LoginButton = styled(CommonButton)`
  margin: 24px auto;
  min-width: 250px;
`;

const LoginContainer: FC<LoginContainerProps> = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LocationState).from?.pathname || "/";
  const auth = useAuth();

  const handleLoginClick = useCallback(() => {
    auth.signIn(username, password, () => {
      navigate(from, { replace: true });
    });
  }, [auth, from, navigate, password, username]);

  const handleUsernameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    },
    [],
  );

  const handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [],
  );

  const handleKeypress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleLoginClick();
      }
    },
    [handleLoginClick],
  );

  return (
    <LoginPageContainer>
      <LoginTitle>Chef Jasper</LoginTitle>
      <LoginTextField
        label="User Name"
        value={username}
        onChange={handleUsernameChange}
        onKeyDown={handleKeypress}
      />
      <LoginTextField
        label="Password"
        value={password}
        type="password"
        onChange={handlePasswordChange}
        onKeyDown={handleKeypress}
      />
      <LoginButton text="Sign in" positive="true" onClick={handleLoginClick} />
    </LoginPageContainer>
  );
};

export default LoginContainer;
