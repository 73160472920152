import React, { FC, useState } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import TopAppBar from "components/navBar/ui/TopAppBar";

interface RootLayoutProps {}

const RootLayoutBox = styled(Box)`
  padding: 0;
  flex-grow: 1;
`;

const RootLayout: FC<RootLayoutProps> = () => {
  const [promptProps, setPromptProps] = useState<{
    isOpen: boolean;
    message: string;
    severity: AlertColor;
  }>({ isOpen: false, message: "", severity: "success" });

  /**
   * Handle prompt message close
   */
  const handlePromptClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): void => {
    if (reason === "clickaway") {
      return;
    }

    setPromptProps({ ...promptProps, isOpen: false });
  };

  return (
    <RootLayoutBox>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={promptProps.isOpen}
        // autoHideDuration={6000}
        onClose={handlePromptClose}
      >
        <Alert
          onClose={handlePromptClose}
          severity={promptProps.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {promptProps.message}
        </Alert>
      </Snackbar>
      <TopAppBar />
      <Outlet />
    </RootLayoutBox>
  );
};

export default RootLayout;
