import { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, AutocompleteProps, styled } from "@mui/material";

type AutocompleteTextFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> & {
  label: string;
  shrink?: boolean;
  error?: boolean;
};

const InputTextField = styled(TextField)`
  display: inline-flex;

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
` as typeof TextField;

const InlineFlexAutocomplete = styled(Autocomplete)`
  display: inline-flex;
` as typeof Autocomplete;

const AutocompleteTextField = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  shrink,
  error,
  ...props
}: AutocompleteTextFieldProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): ReactElement => {
  return (
    <InlineFlexAutocomplete
      disablePortal
      id="inline-flex-autocomplete-text-field"
      {...props}
      renderInput={(params) => (
        <InputTextField
          {...params}
          label={label}
          InputLabelProps={{ shrink }}
          error={error}
        />
      )}
    />
  );
};

export default AutocompleteTextField;
