/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { InventoryService } from './services/InventoryService';
import { OrderingService } from './services/OrderingService';
import { PreparingService } from './services/PreparingService';
import { RecipeService } from './services/RecipeService';
import { ShelfService } from './services/ShelfService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BlueChefAPIClient {

  public readonly inventory: InventoryService;
  public readonly ordering: OrderingService;
  public readonly preparing: PreparingService;
  public readonly recipe: RecipeService;
  public readonly shelf: ShelfService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.3.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.inventory = new InventoryService(this.request);
    this.ordering = new OrderingService(this.request);
    this.preparing = new PreparingService(this.request);
    this.recipe = new RecipeService(this.request);
    this.shelf = new ShelfService(this.request);
  }
}

