import { ApiError } from "client/jspPlatformExperiment";

export type ErrorResponseBodyType = {
  detail: string;
};

export const isErrorResponseBodyType = (
  o: unknown,
): o is ErrorResponseBodyType => {
  return (
    !!o &&
    typeof o === "object" &&
    (o as ErrorResponseBodyType).detail !== undefined
  );
};

export const isApiError = (o: unknown): o is ApiError => {
  return !!o && typeof o === "object" && (o as ApiError).name === "ApiError";
};
