import { RootState } from "store/index";

const foodStockRecords = (state: RootState) =>
  state.foodStockDetail.foodStockRecords;

const foodStockIsLoading = (state: RootState) =>
  state.foodStockDetail.isLoading;

const foodStockError = (state: RootState) => state.foodStockDetail.error;

export default {
  foodStockRecords,
  foodStockIsLoading,
  foodStockError,
};
