import React, { Dispatch, FC, useCallback } from "react";
import MenuSectionEditBox from "components/menu/ui/MenuSectionEditBox";
import { Stack } from "@mui/material";
import PillButton from "components/common/ui/PillButton";
import Box from "@mui/material/Box";
import { FoodItemKey } from "client/jspPlatformExperiment";
import {
  MenuContentAction,
  MenuContentActions,
  TypeDisplayMenuSections,
} from "models/menu/menuContentsReducer";

export interface MenuContentEditSectionProps {
  foodItemOptions: FoodItemKey[];
  menuContents: TypeDisplayMenuSections;
  dispatchMenuContents: Dispatch<MenuContentAction>;
}

const MenuContentsEditSection: FC<MenuContentEditSectionProps> = ({
  foodItemOptions,
  menuContents,
  dispatchMenuContents,
}) => {
  /**
   * Add a new menu section action
   */
  const handleAddMenuSection = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.ADD_SECTION,
    });
  }, [dispatchMenuContents]);

  return (
    <Stack spacing={1}>
      {Object.keys(menuContents).map((sectionKey) => (
        <MenuSectionEditBox
          key={sectionKey}
          displayMenuSectionUUID={sectionKey}
          menuContents={menuContents}
          dispatchMenuContents={dispatchMenuContents}
          foodItemOptions={foodItemOptions}
        />
      ))}
      <Box sx={{ alignSelf: "center" }}>
        <PillButton text="Add menu section" onClick={handleAddMenuSection} />
      </Box>
    </Stack>
  );
};

export default MenuContentsEditSection;
