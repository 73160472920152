import styled from "styled-components";
import Box from "@mui/material/Box";

const RecipeStepGridItemBox = styled(Box)`
  display: flex;
  margin: 0;
  padding: 0.25rem 0;

  @media only screen and (max-width: 500px) {
    gap: 0.5rem;
  }
`;

export default RecipeStepGridItemBox;
