import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuBasic } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import Routes from "models/routes";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DataGridListBox from "components/common/ui/DataGridListBox";
import MenuListTableToolbar from "components/menu/ui/menuList/menuListTableToolbar";

interface MenuListTableProps {}

const MenuListTable: FC<MenuListTableProps> = () => {
  const [rows, setRows] = useState<MenuBasic[]>([]);
  const navigate = useNavigate();

  /**
   * Get the first batch of menus
   */
  useEffect(() => {
    jspPlatformApi()
      .menu.searchMenuMenuMealSearchGet({
        numberPerPage: 100,
        pageIndex: 1,
        returnKeysOnly: true,
      })
      .then((menuKeys) => {
        setRows(menuKeys);
      });
  }, []);

  /**
   * Go to menu detail edit page
   * @param menuId The menu's ID to edit
   */
  const handleEditClick = (menuId: number) => () => {
    navigate(`${Routes.MENU_EDIT}/${menuId}`, {
      replace: false,
      state: { editingMenuId: menuId },
    });
  };

  /**
   * Menu list table column declaration
   */
  const columns: GridColDef<MenuBasic>[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "id", headerName: "ID", width: 90, editable: false },
    { field: "name", headerName: "Name", flex: 180, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: MenuListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows,
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: true,
              id: true,
            },
          },
        }}
      />
    </DataGridListBox>
  );
};

export default MenuListTable;
