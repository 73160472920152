import { useCallback } from "react";
import useAuth from "hooks/useAuth";
import { ApiError } from "client/jspPlatformExperiment";
import { isErrorResponseBodyType } from "models/utils/apiUtils";

export const useJspApiErrorHandler = (): {
  (error: ApiError, callback?: VoidFunction): void;
} => {
  const auth = useAuth();

  return useCallback(
    (error: ApiError, callback?: VoidFunction): void => {
      switch (error.status) {
        case 400:
          if (isErrorResponseBodyType(error.body)) {
            // TODO: Push error message
          }
          break;
        case 401:
          auth.signOut(() => {});
          break;
        case 422:
          break;
        default:
          break;
      }

      if (callback) {
        callback();
      }
    },
    [auth],
  );
};

export default useJspApiErrorHandler;
