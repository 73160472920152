import {
  ChildRecipeStep,
  CookingStep,
  HumanStep,
  IngredientStep,
  OvenStep,
  StepType,
} from "client/blueChef";

const isIngredientStep = (o: unknown): o is IngredientStep => {
  return (o as IngredientStep).step_type === StepType.INGREDIENT;
};

const isCookingStep = (o: unknown): o is CookingStep => {
  return (o as CookingStep).step_type === StepType.COOKING;
};

const isOvenStep = (o: unknown): o is OvenStep => {
  return (o as OvenStep).step_type === StepType.OVEN;
};

const isHumanStep = (o: unknown): o is HumanStep => {
  return (o as HumanStep).step_type === StepType.HUMAN;
};

const isChildRecipeStep = (o: unknown): o is ChildRecipeStep => {
  return (o as ChildRecipeStep).step_type === StepType.CHILD_RECIPE;
};

export {
  isIngredientStep,
  isCookingStep,
  isOvenStep,
  isHumanStep,
  isChildRecipeStep,
};
