import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PromptSnackBar, {
  PromptProps,
  PromptSnackBarProps,
} from "components/common/ui/PromptSnackBar";
import { addApiErrHandler } from "client/portals";
import { isErrorResponseBodyType } from "models/utils/apiUtils";

interface PromptSnackBarContextType extends PromptSnackBarProps {}

export const PromptSnackBarContext = createContext<PromptSnackBarContextType>(
  null!,
);

export const usePromptSnackBar = (): PromptSnackBarContextType => {
  return useContext(PromptSnackBarContext);
};

const PromptSnackBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [promptProps, setPromptProps] = useState<PromptProps>({
    isOpen: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    addApiErrHandler((response): void => {
      switch (response.status) {
        case 200:
          break;
        default:
          if (isErrorResponseBodyType(response.data)) {
            setPromptProps({
              isOpen: true,
              message: response.data.detail,
              severity: "error",
            });
          }
          break;
      }
    });
  });

  const promptContext = useMemo(
    () => ({ promptProps, setPromptProps }),
    [promptProps],
  );

  return (
    <PromptSnackBarContext.Provider value={promptContext}>
      {children}
      <PromptSnackBar
        promptProps={promptProps}
        setPromptProps={setPromptProps}
      />
    </PromptSnackBarContext.Provider>
  );
};

export default PromptSnackBarProvider;
