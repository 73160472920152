import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import jspPlatformApi, { addApiErrHandler } from "client/portals";
import { UserRead } from "client/jspPlatformExperiment";

export interface AuthContextType {
  accessToken: string | undefined;
  user: UserRead | undefined;
  refreshUser: (user: UserRead) => void;
  signIn: (username: string, password: string, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(() => {
    const savedAccessToken = localStorage.getItem("accessToken");
    return savedAccessToken || undefined;
  });

  const [user, setUser] = useState<UserRead>();

  useEffect(() => {
    localStorage.setItem("accessToken", accessToken || "");
  }, [accessToken]);

  const refreshUser = useCallback((userData: UserRead) => {
    setUser(userData);
  }, []);

  const signIn = useCallback(
    (username: string, password: string, callback: VoidFunction) => {
      jspPlatformApi()
        .authentication.loginForAccessTokenAuthTokenPost({
          formData: {
            username,
            password,
          },
        })
        .then((data) => {
          localStorage.setItem("accessToken", data.access_token || "");
          setUser(data.user);
          setAccessToken(data.access_token);
          callback();
        })
        .catch(() => {
          setAccessToken(undefined);
        });
    },
    [setAccessToken],
  );

  const signOut = useCallback(
    (callback: VoidFunction) => {
      setAccessToken(undefined);
      setUser(undefined);
      callback();
    },
    [setAccessToken],
  );

  useEffect(() => {
    addApiErrHandler((response): void => {
      switch (response.status) {
        case 401:
          signOut(() => {});
          break;
        default:
          break;
      }
    });
  });

  const authContext = useMemo(
    () => ({ accessToken, user, refreshUser, signIn, signOut }),
    [accessToken, user, refreshUser, signIn, signOut],
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
