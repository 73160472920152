import { styled, Switch } from "@mui/material";

// eslint-disable-next-line max-len
const iconYes = `'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
  "#fff",
)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>'`;

// eslint-disable-next-line max-len
const iconNo = `'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
  "#fff",
)}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>'
`;

const YesNoSwitch = styled(Switch)`
  padding: 8px;

  & .MuiSwitch-track {
    border-radius: 11px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }

    &:before {
      background-image: url(${iconYes});
      left: 12px;
    }

    &:after {
      background-image: url(${iconNo});
      right: 12px;
    }
  }

  & .MuiSwitch-thumb {
    box-shadow: none;
    width: 16px;
    height: 16px;
    margin: 2px;
  }
` as typeof Switch;

export default YesNoSwitch;
