import React, { CSSProperties, FC, useCallback, useEffect } from "react";
import { Container } from "@mui/material";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  useEdgesState,
  useNodesState,
} from "reactflow";
import styled from "styled-components";

import "reactflow/dist/style.css";
import {
  ChildRecipeStep,
  CookingStep,
  HumanStep,
  IngredientStep,
  OvenStep,
} from "client/blueChef";
import { useAppSelector } from "hooks/useReduxStore";
import {
  isChildRecipeStep,
  isCookingStep,
  isHumanStep,
  isIngredientStep,
  isOvenStep,
} from "models/stepTypes";
import recipeDetailSelector from "store/selectors/recipe/recipeDetailSelector";

interface RecipeContainerProps {}

const RecipePageContainer = styled(Container)`
  padding: 20px 0;
`;

const RecipeFlowchart = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
`;

const RecipeContainer: FC<RecipeContainerProps> = () => {
  // const [displayRecipeName, setDisplayRecipeName] = useState("");
  // const [displayRecipeData, setDisplayRecipeData] =
  //   useState<RecipeFrontEndTransaction>();
  const displayRecipeData = useAppSelector(recipeDetailSelector);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params: Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  // The effect to update display recipe graph when the display recipe data changed
  useEffect(() => {
    if (!displayRecipeData) {
      setNodes([]);
      setEdges([]);
      return;
    }

    // Figure out the steps of each step group
    let allSteps: (
      | IngredientStep
      | CookingStep
      | OvenStep
      | HumanStep
      | ChildRecipeStep
    )[] = displayRecipeData.ing_steps;
    allSteps = allSteps.concat(displayRecipeData.cooking_steps);
    allSteps = allSteps.concat(displayRecipeData.oven_steps);
    allSteps = allSteps.concat(displayRecipeData.human_steps);
    allSteps = allSteps.concat(displayRecipeData.child_recipe_steps);
    const stepGroup2Step: Record<
      number,
      (IngredientStep | CookingStep | OvenStep | HumanStep | ChildRecipeStep)[]
    > = {};
    allSteps.forEach((step) => {
      let curGroupSteps: (
        | IngredientStep
        | CookingStep
        | OvenStep
        | HumanStep
        | ChildRecipeStep
      )[] = [];
      if (stepGroup2Step[step.step_group_num]) {
        curGroupSteps = stepGroup2Step[step.step_group_num];
      }
      curGroupSteps.push(step);
      stepGroup2Step[step.step_group_num] = curGroupSteps;
    });

    // Handle nodes and edges update
    const newNodes: Node[] = [];
    const newEdges: Edge[] = [];
    Object.keys(stepGroup2Step).forEach((groupNum) => {
      stepGroup2Step[Number(groupNum)].forEach((step, i) => {
        // Add the edges
        if (step.step_group_num > 1) {
          stepGroup2Step[step.step_group_num - 1].forEach((preStep) => {
            newEdges.push({
              id: `e${preStep.step_num}-${step.step_num}`,
              source: `${preStep.step_num}`,
              target: `${step.step_num}`,
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            });
          });
        }

        // Add the new nodes
        let labelData: string;
        let nodeStyle: CSSProperties;
        // if (step instanceof IngredientStep) {
        // if (step.step_type === 'ingredient') {
        if (isIngredientStep(step)) {
          labelData = `${step.amount}g ${step.ing_name}`;
          nodeStyle = {
            background: "rgb(31, 96, 196, 0.8)",
            color: "white",
            fontWeight: 800,
            border: 0,
          };
        } else if (isCookingStep(step)) {
          labelData = `${step.cook_temp}°C ${step.cook_time}s ${step.cook_speed}spd`;
          nodeStyle = {
            background: "rgb(255, 152, 48, 0.8)",
            color: "white",
            fontWeight: 800,
            border: 0,
          };
        } else if (isOvenStep(step)) {
          labelData = `${step.cook_temp}°C ${step.cook_time}s ${step.cook_humidity}% humidity at mode: ${step.mode}`;
          nodeStyle = {
            background: "rgb(255, 120, 10, 0.8)",
            color: "white",
            fontWeight: 800,
            border: 0,
          };
        } else if (isChildRecipeStep(step)) {
          labelData = "";
          if (step.as_ingredient_settings) {
            labelData += `${step.as_ingredient_settings.amount}g `;
          }
          labelData += step.child_recipe.name;
          nodeStyle = {
            fontWeight: 800,
          };
        } else {
          // human step
          isHumanStep(step);
          labelData = step.instruction;
          nodeStyle = {
            background: "rgb(133, 250, 255, 0.8)",
            color: "black",
            fontWeight: 800,
            border: 0,
          };
        }

        newNodes.push({
          id: `${step.step_num}`,
          // type: step.step_type,
          position: {
            x: i * 160,
            y: step.step_group_num * 100,
          },
          data: {
            label: labelData,
          },
          style: nodeStyle,
        });
      });
    });
    setNodes(newNodes);
    setEdges(newEdges);
  }, [displayRecipeData, setNodes, setEdges]);

  return (
    <RecipePageContainer>
      <RecipeFlowchart>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          attributionPosition="top-right"
        >
          <Controls />
          <MiniMap />
          <Background />
        </ReactFlow>
      </RecipeFlowchart>
    </RecipePageContainer>
  );
};

export default RecipeContainer;
