import { useRouteError } from "react-router-dom";
import styled from "styled-components";
import { Container } from "@mui/material";
import { FC } from "react";

interface DefaultErrorPageProps {}

const DefaultErrorPageContainer = styled(Container)`
  padding: 20px 0;
  text-align: center;
`;

const DefaultErrorPage: FC<DefaultErrorPageProps> = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <DefaultErrorPageContainer>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>
          {(error as Error)?.message ||
            (error as { statusText?: string })?.statusText}
        </i>
      </p>
    </DefaultErrorPageContainer>
  );
};

export default DefaultErrorPage;
