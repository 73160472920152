import { RootState } from "store/index";

const foodItemBasic = (state: RootState) => state.foodItemDetail.foodItem;

const foodTags = (state: RootState) => state.foodItemDetail.foodTags;

const foodNutritionRecords = (state: RootState) =>
  state.foodItemDetail.foodNutritionRecords;

const foodRecipes = (state: RootState) => state.foodItemDetail.foodRecipes;

const foodSuppliers = (state: RootState) => state.foodItemDetail.foodSuppliers;

const foodIsLoading = (state: RootState) => state.foodItemDetail.isLoading;

const foodError = (state: RootState) => state.foodItemDetail.error;

export default {
  foodItemBasic,
  foodTags,
  foodNutritionRecords,
  foodRecipes,
  foodSuppliers,
  foodIsLoading,
  foodError,
};
