import { FC, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApiError } from "client/blueChef";
import CommonButton from "components/common/ui/CommonButton";
import { useAppDispatch } from "hooks/useReduxStore";
import { actions } from "store/slices/foodItem/foodItemDetailSlice";
import { getFoodItemFromBCIngredient } from "models/utils/foodItemUtils";
import { SpacedAutocompleteInput } from "components/common/ui/SpacedTextInput";
import Routes from "models/routes";
import useJspApiErrorHandler from "hooks/useJspApiErrorHandler";
import { blueChefAPI } from "client/portals";
import IngredientListTable from "components/food/ui/ingredientList/IngredientListTable";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";

interface IngredientListPageProps {}

const IngredientListPage: FC<IngredientListPageProps> = () => {
  const [ingredientNameList, setIngredientNameList] = useState<Array<string>>(
    [],
  );
  const [selectedIngredientName, setSelectedIngredientName] =
    useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiErrorHandler = useJspApiErrorHandler();

  /**
   * The effect to update ingredient name list when loading the page for the first time
   */
  useEffect(() => {
    if (ingredientNameList.length === 0) {
      blueChefAPI.inventory
        .getAllIngredientsAllIngredientsGet()
        .then((ingredientNames) => {
          setIngredientNameList(ingredientNames);
        })
        .catch((reason: ApiError) => {
          apiErrorHandler(reason, () => {
            setIngredientNameList([]);
          });
        });
    }
  }, [apiErrorHandler, ingredientNameList]);

  /**
   * Handle to copy ingredient text input changes
   */
  const handleToCopyIngredientChange = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      setSelectedIngredientName(newValue !== null ? newValue : "");
    },
    [],
  );

  /**
   * Button click - Copy ingredient data from the old platform
   */
  const handleCopyClick = useCallback(() => {
    blueChefAPI.inventory
      .getIngredientInfoIngredientGet(selectedIngredientName)
      .then((ingData) => {
        dispatch(
          actions.setFoodItemDetail(getFoodItemFromBCIngredient(ingData)),
        );
        navigate(Routes.FOOD_INGREDIENT_CREATE, {
          replace: false,
        });
      })
      .catch(() => {});
  }, [selectedIngredientName, dispatch, navigate]);

  return (
    <Container>
      <BottomSpacedPageHeader>Ingredients</BottomSpacedPageHeader>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={10}>
          <SpacedAutocompleteInput
            options={ingredientNameList}
            label="Select from old database"
            onChange={handleToCopyIngredientChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CommonButton text="Copy" positive="true" onClick={handleCopyClick} />
        </Grid>
        <Grid item xs={12}>
          <IngredientListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default IngredientListPage;
