import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FoodItem,
  FoodItemBasic,
  FoodItemNutrition,
  FoodItemSupplierInfo,
  FoodRecipe,
  FoodTagBasic,
  GetFoodItemFoodItemFoodIdGetData,
} from "client/jspPlatformExperiment";
import { isEmpty } from "lodash";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

interface FoodItemDetailStateProps {
  /** Return value of food item data from the Jasper Platform API call */
  foodItem: FoodItemBasic | null;
  foodTags: FoodTagBasic[];
  foodNutritionRecords: FoodItemNutrition[];
  foodRecipes: FoodRecipe[];
  foodSuppliers: FoodItemSupplierInfo[];
  isLoading: boolean;
  error: string | null;
}

const initialState: FoodItemDetailStateProps = {
  foodItem: null,
  foodTags: [],
  foodNutritionRecords: [],
  foodRecipes: [],
  foodSuppliers: [],
  isLoading: false,
  error: null,
};

const fetchFoodItemDetail = createAsyncThunk(
  "foodItemDetail/fetchFoodItemDetail",
  async (
    input: GetFoodItemFoodItemFoodIdGetData,
    { signal, rejectWithValue },
  ) => {
    try {
      const recipeFoodItemData =
        (await jspPlatformApi().foodItem.getFoodItemFoodItemFoodIdGet(
          input,
        )) as FoodItem;
      return recipeFoodItemData;
    } catch (error) {
      let errMsg: string;
      if (isApiError(error) && isErrorResponseBodyType(error.body)) {
        errMsg = error.body.detail;
      } else {
        errMsg = "Unknown error!";
      }
      return rejectWithValue(errMsg);
    }
  },
);

const updateFoodItemDetail = (
  state: FoodItemDetailStateProps,
  action: PayloadAction<FoodItem | null>,
) => {
  const {
    food_tags: foodTags,
    food_nutrition_records: foodNutritionRecords,
    food_recipes: foodRecipes,
    food_suppliers: foodSuppliers,
    ...foodItemBasic
  } = action.payload ?? {};
  return {
    ...state,
    foodItem: isEmpty(foodItemBasic) ? null : (foodItemBasic as FoodItemBasic),
    foodTags: foodTags ?? [],
    foodNutritionRecords: foodNutritionRecords ?? [],
    foodRecipes: foodRecipes ?? [],
    foodSuppliers: foodSuppliers ?? [],
  };
};

export const foodItemDetailSlice = createSlice({
  name: "foodItemDetailSlice",
  initialState,
  reducers: {
    setFoodItemDetail: updateFoodItemDetail,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoodItemDetail.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchFoodItemDetail.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodItemDetail(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchFoodItemDetail.rejected, (state, action) => {
      return { ...state, isLoading: false, error: action.payload as string };
    });
  },
});

export const actions = { ...foodItemDetailSlice.actions, fetchFoodItemDetail };

export default foodItemDetailSlice.reducer;
