import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid, InputAdornment } from "@mui/material";
import SpacedTextInput, {
  SpacedDateTimePicker,
} from "components/common/ui/SpacedTextInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Menu } from "client/jspPlatformExperiment";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import { isNumber } from "@mui/x-data-grid/internals";

dayjs.extend(duration);

interface MenuBasicInfoEditSectionProps {
  menuBasicInfo: Omit<Menu, "sections">;
  setMenuBasicInfo: Dispatch<SetStateAction<Omit<Menu, "sections">>>;
}

const MenuBasicInfoEditSection: FC<MenuBasicInfoEditSectionProps> = ({
  menuBasicInfo,
  setMenuBasicInfo,
}) => {
  const [servingDateTime, setServingDateTime] = useState<Dayjs | null>(
    menuBasicInfo.serving_datetime
      ? dayjs(menuBasicInfo.serving_datetime)
      : null,
  );

  /**
   * Sync the display serving datetime and from the data
   */
  useEffect(() => {
    if (menuBasicInfo.serving_datetime && !servingDateTime) {
      setServingDateTime(dayjs(menuBasicInfo.serving_datetime));
    }
  }, [menuBasicInfo.serving_datetime, servingDateTime]);

  /**
   * Handle menu basic info text input changes
   */
  const handleTextInputChanges = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const valueInput: number | string = event.target.value;
      setMenuBasicInfo({
        ...menuBasicInfo,
        [event.target.name]: valueInput,
      });
    },
    [menuBasicInfo, setMenuBasicInfo],
  );

  /**
   * Handle serving date time change
   */
  const handleServingDatetimeChange = useCallback(
    (newValue: Dayjs | null) => {
      // setServingDateTime(newValue);
      if (newValue?.isValid()) {
        setMenuBasicInfo({
          ...menuBasicInfo,
          serving_datetime: newValue.toISOString(),
        });
      } else {
        setMenuBasicInfo({
          ...menuBasicInfo,
          serving_datetime: null,
        });
      }
    },
    [menuBasicInfo, setMenuBasicInfo],
  );

  /**
   * Handle time duration input changes
   */
  const handleTimeDurationChanges = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let valueInput: number | string = event.target.value;
      if (!valueInput.endsWith(".")) {
        valueInput = Number(valueInput) || valueInput;
      }
      if (isNumber(valueInput)) {
        setMenuBasicInfo({
          ...menuBasicInfo,
          [event.target.name]: dayjs
            .duration(valueInput, "hours")
            .toISOString(),
        });
      } else {
        setMenuBasicInfo({
          ...menuBasicInfo,
          [event.target.name]: "",
        });
      }
    },
    [menuBasicInfo, setMenuBasicInfo],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SpacedTextInput
            label="Menu name"
            name="name"
            value={menuBasicInfo.name ?? ""}
            onChange={handleTextInputChanges}
          />
        </Grid>
        <Grid item xs={12} md={8} xl={5}>
          <SpacedDateTimePicker
            label="Serving Date and Time (Leave this blank for template)"
            ampm={false}
            onChange={handleServingDatetimeChange}
            value={servingDateTime}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <SpacedTextInput
            label="Publish prior to the serving time"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">hours</InputAdornment>
              ),
            }}
            name="publish_prior_to_serving_time"
            value={
              menuBasicInfo.publish_prior_to_serving_time !== ""
                ? dayjs
                    .duration(
                      menuBasicInfo.publish_prior_to_serving_time ?? "P2D",
                    )
                    .asHours()
                : ""
            }
            onChange={handleTimeDurationChanges}
          />
        </Grid>
        <Grid item xs={12} xl={4}>
          <SpacedTextInput
            label="Cap finalization prior to the serving time"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">hours</InputAdornment>
              ),
            }}
            name="cap_finalization_prior_to_serving_time"
            value={
              menuBasicInfo.cap_finalization_prior_to_serving_time !== ""
                ? dayjs
                    .duration(
                      menuBasicInfo.cap_finalization_prior_to_serving_time ??
                        "P1D",
                    )
                    .asHours()
                : ""
            }
            onChange={handleTimeDurationChanges}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default MenuBasicInfoEditSection;
