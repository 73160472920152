import styled, { css } from "styled-components";
import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField";
import { FC } from "react";

export const SpacedTextCSS = css`
  width: 100%;
  font-size: 0.5rem;
  background-color: rgb(255, 255, 255, 80%);
  border-radius: 0.3rem;

  label {
    color: #a0aab4;
  }

  label.Mui-error {
    font-weight: 800;
    border-radius: 0.3rem;
  }

  label.Mui-focused {
    color: #a0aab4;

    &.Mui-error {
      color: red;
    }
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #eee;
    }

    &.Mui-disabled {
      background-color: #eee;

      .MuiOutlinedInput-notchedOutline {
        border-color: #eee;
      }
    }

    &:hover fieldset {
      border-color: #eee;
    }

    &.Mui-focused fieldset {
      border-color: #eee;
    }
  }
`;

export const StyledSpacedTextField = styled(TextField)`
  display: inline-flex;

  ${SpacedTextCSS}
` as typeof TextField;

interface SpacedTextFieldProps extends Omit<TextFieldProps, "variant"> {
  variant?: TextFieldVariants;
}

const SpacedTextField: FC<SpacedTextFieldProps> = ({ ...props }) => {
  return (
    <StyledSpacedTextField
      InputProps={{ ...props.InputProps, readOnly: true }}
      {...props}
    />
  );
};

export default SpacedTextField;
