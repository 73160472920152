import styled from "styled-components";
import Typography from "@mui/material/Typography";

const PageHeader = styled(Typography)`
  padding-top: 32px;
  font-size: 2.1rem;
  font-weight: 100;
`;

export default PageHeader;
