import { DishOrderPortions } from "client/jspPlatformExperiment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { v4 as uuidv4 } from "uuid";

interface DisplayDishOrderPortions extends Omit<DishOrderPortions, "food_id"> {
  food_id?: number;
}

export type TypeDisplayPortionOrders = Record<string, DisplayDishOrderPortions>;

interface DishPortionOrdersStateProps {
  dishPortionOrders: TypeDisplayPortionOrders;
  isLoading: boolean;
  error: string | null;
}

const initialState: DishPortionOrdersStateProps = {
  dishPortionOrders: { [uuidv4()]: { amount: 0 }, [uuidv4()]: { amount: 0 } },
  isLoading: false,
  error: null,
};

const updateADishPortionOrder = (
  state: DishPortionOrdersStateProps,
  action: PayloadAction<{
    entityUUID: string;
    dishPortionOrder: Partial<DisplayDishOrderPortions>;
  } | null>,
) => {
  const newPortionOrders = action.payload
    ? merge({}, state.dishPortionOrders, {
        ...state.dishPortionOrders,
        [action.payload.entityUUID]: action.payload.dishPortionOrder,
      })
    : state.dishPortionOrders;
  return {
    ...state,
    dishPortionOrders: newPortionOrders,
  };
};

const deleteADishPortionOrder = (
  state: DishPortionOrdersStateProps,
  action: PayloadAction<{
    entityUUID: string;
  }>,
) => {
  const { [action.payload?.entityUUID]: toDelete, ...newPortionOrders } =
    state.dishPortionOrders;
  return {
    ...state,
    dishPortionOrders: newPortionOrders,
  };
};

const appendADishPortionOrder = (state: DishPortionOrdersStateProps) => ({
  ...state,
  dishPortionOrders: {
    ...state.dishPortionOrders,
    [uuidv4()]: { amount: 0 },
  },
});

export const dishPortionOrdersSlice = createSlice({
  name: "dishPortionOrdersSlice",
  initialState,
  reducers: {
    setDishPortionOrders: updateADishPortionOrder,
    appendDishPortionOrder: appendADishPortionOrder,
    deleteDishPortionOrder: deleteADishPortionOrder,
  },
});

export const actions = {
  ...dishPortionOrdersSlice.actions,
};

export default dishPortionOrdersSlice.reducer;
