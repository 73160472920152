import { FC, useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { ApiError, FoodRecipeKey } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isErrorResponseBodyType } from "models/utils/apiUtils";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import recipeListTableToolbar from "components/food/ui/recipeList/recipeListTableToolbar";
import DataGridListBox from "components/common/ui/DataGridListBox";

interface RecipeListTableProps {}

const RecipeListTable: FC<RecipeListTableProps> = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<FoodRecipeKey[]>([]);

  /**
   * Get the first batch of recipe keys
   */
  useEffect(() => {
    jspPlatformApi()
      .recipe.searchFoodRecipesFoodRecipeSearchGet({
        numberPerPage: 100,
        pageIndex: 1,
        returnKeysOnly: true,
      })
      .then((recipeKeys) => {
        setRows(recipeKeys);
      })
      .catch((reason: ApiError) => {
        if (isErrorResponseBodyType(reason.body)) {
          // console.log(reason.body.detail);
        }
      });
  }, []);

  /**
   * Go to recipe detail edit page
   * @param id The recipe's food ID, not the recipe ID
   */
  const handleEditClick = (foodId: number, recipeId: number) => () => {
    navigate(`${Routes.FOOD_RECIPE_EDIT}/${foodId}/${recipeId}`, {
      replace: false,
      state: { editingFoodId: foodId },
    });
  };

  const columns: GridColDef<FoodRecipeKey>[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.food_id, row.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "food_id", headerName: "ID", width: 90, editable: false },
    { field: "food_name", headerName: "Dish Name", flex: 180, editable: false },
    { field: "name", headerName: "Recipe Name", flex: 180, editable: false },
    { field: "id", headerName: "Recipe ID", width: 90, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: recipeListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows,
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: false,
              id: false,
            },
          },
        }}
      />
    </DataGridListBox>
  );
};

export default RecipeListTable;
