/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_recipe_from_file_upload_recipe_file_post } from '../models/Body_update_recipe_from_file_upload_recipe_file_post';
import type { RecipeFrontEndTransaction } from '../models/RecipeFrontEndTransaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RecipeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Recipe
   * fetch recipe
   *
   * Args:
   * recipe_name (`str`): The recipe name.
   * skip_cost (`bool`): Whether to skip cost calculation when fetching recipe.
   * skip_nutrition (`bool`): Whether to skip calorie calculation when fetching recipe.
   * skip_cooking_time (`bool`): Whether to skip cooking time calculation when fetching recipe.
   * skip_recipe_code (`bool`): Whether to skip recipe code generation when fetching recipe.
   * silently_validate (`bool`):
   * Whether validate cost/cooking time calculation and recipe code generation silently
   * without return HTTP error codes but provide the error message in a field of recipe
   * front-end transaction JSON model.
   *
   * Returns:
   * The recipe front-end transaction model.
   * @param recipeName
   * @param skipCost
   * @param skipNutrition
   * @param skipCookingTime
   * @param skipRecipeCode
   * @param silentlyValidate
   * @returns RecipeFrontEndTransaction Successful Response
   * @throws ApiError
   */
  public getRecipeRecipeGet(
    recipeName: string,
    skipCost: boolean = false,
    skipNutrition: boolean = false,
    skipCookingTime: boolean = false,
    skipRecipeCode: boolean = false,
    silentlyValidate: boolean = true,
  ): CancelablePromise<RecipeFrontEndTransaction> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/recipe',
      query: {
        'recipe_name': recipeName,
        'skip_cost': skipCost,
        'skip_nutrition': skipNutrition,
        'skip_cooking_time': skipCookingTime,
        'skip_recipe_code': skipRecipeCode,
        'silently_validate': silentlyValidate,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Or Replace Recipe
   * Add or replace recipe
   *
   * Args:
   * recipe (`RecipeFrontEndTransaction`): The recipe to add or update.
   * skip_cost (`bool`): Whether to skip cost calculation check when saving/updating recipe.
   * skip_nutrition (`bool`): Whether to skip calorie calculation when fetching recipe.
   * skip_cooking_time (`bool`):
   * Whether to skip cooking time calculation check when saving/updating recipe.
   * skip_recipe_code (`bool`):
   * Whether to skip recipe code generation check when saving/updating recipe.
   *
   * Returns:
   * recipe.id (`int`)
   * @param requestBody
   * @param skipCost
   * @param skipNutrition
   * @param skipCookingTime
   * @param skipRecipeCode
   * @returns number Successful Response
   * @throws ApiError
   */
  public addOrReplaceRecipeRecipePut(
    requestBody: RecipeFrontEndTransaction,
    skipCost: boolean = false,
    skipNutrition: boolean = false,
    skipCookingTime: boolean = false,
    skipRecipeCode: boolean = false,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/recipe',
      query: {
        'skip_cost': skipCost,
        'skip_nutrition': skipNutrition,
        'skip_cooking_time': skipCookingTime,
        'skip_recipe_code': skipRecipeCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Recipe
   * Delete a recipe
   *
   * Args:
   * recipe_name(`str`): The recipe name to delete
   *
   * Returns:
   * The deleted recipe (`RecipeFrontEndTransaction`)
   * @param recipeName
   * @returns RecipeFrontEndTransaction Successful Response
   * @throws ApiError
   */
  public deleteRecipeRecipeDelete(
    recipeName: string,
  ): CancelablePromise<RecipeFrontEndTransaction> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/recipe',
      query: {
        'recipe_name': recipeName,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Recipe From Id
   * fetch recipe from its id
   *
   * Args:
   * recipe_id (`int`): The recipe id.
   * skip_cost (`bool`): Whether to skip cost calculation when fetching recipe.
   * skip_nutrition (`bool`): Whether to skip calorie calculation when fetching recipe.
   * skip_cooking_time (`bool`): Whether to skip cooking time calculation when fetching recipe.
   * skip_recipe_code (`bool`): Whether to skip recipe code generation when fetching recipe.
   * silently_validate (`bool`):
   * Whether validate cost/cooking time calculation and recipe code generation silently
   * without return HTTP error codes but provide the error message in a field of recipe
   * front-end transaction JSON model.
   *
   * Returns:
   * The recipe front-end transaction model.
   * @param recipeId
   * @param skipCost
   * @param skipNutrition
   * @param skipCookingTime
   * @param skipRecipeCode
   * @param silentlyValidate
   * @returns RecipeFrontEndTransaction Successful Response
   * @throws ApiError
   */
  public getRecipeFromIdRecipeFromIdGet(
    recipeId: number,
    skipCost: boolean = false,
    skipNutrition: boolean = false,
    skipCookingTime: boolean = false,
    skipRecipeCode: boolean = false,
    silentlyValidate: boolean = true,
  ): CancelablePromise<RecipeFrontEndTransaction> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/recipe_from_id',
      query: {
        'recipe_id': recipeId,
        'skip_cost': skipCost,
        'skip_nutrition': skipNutrition,
        'skip_cooking_time': skipCookingTime,
        'skip_recipe_code': skipRecipeCode,
        'silently_validate': silentlyValidate,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get A List Of Recipes
   * Fetch a list of recipe datas
   *
   * Args:
   * recipe_names: The list of recipe names to fetch data.
   * skip_cost (`bool`): Whether to skip cost calculation when fetching recipe.
   * skip_nutrition (`bool`): Whether to skip calorie calculation when fetching recipe.
   * skip_cooking_time (`bool`): Whether to skip cooking time calculation when fetching recipe.
   * skip_recipe_code (`bool`): Whether to skip recipe code generation when fetching recipe.
   * silently_validate (`bool`):
   * Whether validate cost/cooking time calculation and recipe code generation silently
   * without return HTTP error codes but provide the error message in a field of recipe
   * front-end transaction JSON model.
   *
   * Returns:
   * The list of recipe front-end transaction models.
   * @param recipeNames
   * @param skipCost
   * @param skipNutrition
   * @param skipCookingTime
   * @param skipRecipeCode
   * @param silentlyValidate
   * @returns RecipeFrontEndTransaction Successful Response
   * @throws ApiError
   */
  public getAListOfRecipesRecipeBatchGet(
    recipeNames: Array<string>,
    skipCost: boolean = false,
    skipNutrition: boolean = false,
    skipCookingTime: boolean = false,
    skipRecipeCode: boolean = false,
    silentlyValidate: boolean = true,
  ): CancelablePromise<Array<RecipeFrontEndTransaction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/recipe/batch',
      query: {
        'recipe_names': recipeNames,
        'skip_cost': skipCost,
        'skip_nutrition': skipNutrition,
        'skip_cooking_time': skipCookingTime,
        'skip_recipe_code': skipRecipeCode,
        'silently_validate': silentlyValidate,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Or Replace List Of Recipes
   * Add or replace a list of recipes
   *
   * Args:
   * recipes (`List[RecipeFrontEndTransaction]`): The list of recipes to add or update.
   * skip_cost (`bool`): Whether to skip cost calculation check when saving/updating recipe.
   * skip_nutrition (`bool`): Whether to skip calorie calculation when fetching recipe.
   * skip_cooking_time (`bool`):
   * Whether to skip cooking time calculation check when saving/updating recipe.
   * skip_recipe_code (`bool`):
   * Whether to skip recipe code generation check when saving/updating recipe.
   *
   * Returns:
   * List of recipe IDs that been created/updated
   * @param requestBody
   * @param skipCost
   * @param skipNutrition
   * @param skipCookingTime
   * @param skipRecipeCode
   * @returns number Successful Response
   * @throws ApiError
   */
  public addOrReplaceListOfRecipesRecipeBatchPut(
    requestBody: Array<RecipeFrontEndTransaction>,
    skipCost: boolean = false,
    skipNutrition: boolean = false,
    skipCookingTime: boolean = false,
    skipRecipeCode: boolean = false,
  ): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/recipe/batch',
      query: {
        'skip_cost': skipCost,
        'skip_nutrition': skipNutrition,
        'skip_cooking_time': skipCookingTime,
        'skip_recipe_code': skipRecipeCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sync Recipes From Remote
   * Sync local recipes with remote server
   *
   * Args:
   * remote_ip (`str`): The remote host IP.
   * remote_port (`Optional[int]`): The remote Blue Chef port. Default to 8000
   *
   * Returns:
   * None
   * @param remoteIp
   * @param remotePort
   * @returns any Successful Response
   * @throws ApiError
   */
  public syncRecipesFromRemoteSyncRecipesFromRemotePost(
    remoteIp: string,
    remotePort: number = 8000,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sync_recipes_from_remote',
      query: {
        'remote_ip': remoteIp,
        'remote_port': remotePort,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Update Recipe From File
   * Chef upload recipe file to update database
   *
   * Args:
   * recipe_file: The recipe file in Excel.
   *
   * Returns:
   * The updated/created recipe names and IDs.
   * @param formData
   * @returns string Successful Response
   * @throws ApiError
   */
  public updateRecipeFromFileUploadRecipeFilePost(
    formData: Body_update_recipe_from_file_upload_recipe_file_post,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/upload_recipe_file',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Download Recipe To File
   * Returns recipe information in downloadable excel
   *
   * Args:
   * recipe_name_list (`list`): List of recipes to be downloaded.
   *
   * Returns:
   * response (`StreamingResponse`): An Excel file that can be downloaded.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public downloadRecipeToFileDownloadRecipeFilePost(
    requestBody: Array<string>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/download_recipe_file',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Calculate Recipe Portion Costs
   * Calculate cost per portion for a list of recipes
   *
   * Args:
   * dish_list (`list`): List of dish name.
   * use_sysco_price (`bool`): `True` if we use sysco price, `False` if you use retailer price.
   *
   * Returns:
   * dish_costs (`dict`): The portion cost for each dish in dictionary.
   * @param requestBody
   * @param useSyscoPrice
   * @returns any Successful Response
   * @throws ApiError
   */
  public calculateRecipePortionCostsRecipePortionCostsPost(
    requestBody: Array<string>,
    useSyscoPrice: boolean = true,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/recipe_portion_costs',
      query: {
        'use_sysco_price': useSyscoPrice,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Recipes
   * Fetch all the recipe names with option to filter by category or binary tags
   *
   * Args:
   * category (`Optional[str]`): the category name to filter by.
   * subcategory (`Optional[str]`): the subcategory name to filter by.
   * tags (`Optional[str]`):
   * The comma separated string with tag names to filter by.
   * i.e. `vegetarian, apt_for_lunchbox` or `robot_tested`.
   *
   * Returns:
   * recipe_list (`list[str]`): List of recipe names.
   * @param category
   * @param subcategory
   * @param tags
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAllRecipesAllRecipesGet(
    category?: string,
    subcategory?: string,
    tags?: string,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/all_recipes',
      query: {
        'category': category,
        'subcategory': subcategory,
        'tags': tags,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

}
