import styled, { css } from "styled-components";
import { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";

interface CommonButtonProps extends ButtonProps {
  text: string;
  positive?: string;
  danger?: string;
  natural?: string;
}

const PureButton = styled(Button)<Omit<CommonButtonProps, "text">>`
  border-radius: 0.3rem;
  margin: 0.5em 1em;
  padding: 0.25em 1em;

  &:hover {
    color: #ffcf84;
  }

  ${(props) => {
    let background = css`rgb(244, 244, 244)`;
    let borderColor = background;
    let color = css`rgb(120, 203, 255)`;
    if (props.natural) {
      background = css`"none"`;
      color = css`rgb(200, 200, 200)`;
      borderColor = css`rgb(220, 220, 220, 0)`;
    }
    if (props.positive) {
      background = css`rgb(120, 203, 255)`;
      color = css`rgb(255, 255, 255)`;
      borderColor = background;
    }
    if (props.danger) {
      background = css`rgb(246, 97, 97)`;
      color = css`rgb(255, 255, 255)`;
      borderColor = background;
    }

    return css`
      background: ${background};
      border: 0.15rem solid ${borderColor};
      color: ${color};

      &:hover {
        background: #ffcf84;
        border: 0.15rem solid #ffcf84;
        color: white;
      }
    `;
  }};
`;

const CommonButton = forwardRef<HTMLButtonElement, CommonButtonProps>(
  ({ text, ...props }, ref) => {
    return (
      <PureButton {...props} ref={ref}>
        {text}
      </PureButton>
    );
  },
);

export default CommonButton;
