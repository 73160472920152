/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DishOrder } from '../models/DishOrder';
import type { DishOrderFrontEndTransaction } from '../models/DishOrderFrontEndTransaction';
import type { MealTypes } from '../models/MealTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Place Dish Orders
   * Place order into the dish order queue
   *
   * Args:
   * dish_orders_input (`List[DishOrder]`): The list of the dish orders
   *
   * Returns:
   * JSONResponse of the order status
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public placeDishOrdersPlaceDishOrdersPost(
    requestBody: Array<DishOrder>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/place_dish_orders',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch Customer Dish Orders Oven Timer
   * Fetch the oven recipes and summary them to feed into oven timer the chef using
   *
   * Notes:
   * This endpoint is temporary and could be removed once the oven recipe scheduling is handled
   * the same way as the MC recipes.
   *
   * Args:
   * fetch_start_time (`datetime`): The start time boundary of orders fetching.
   * fetch_end_time (`Optional[datetime]`): The end time boundary of orders fetching.
   * forecast (`bool`): Whether fetching the forecast orders from the remote server.
   *
   * Returns:
   * The oven recipe summaries can feed into oven timer software.
   * @param fetchStartTime
   * @param fetchEndTime
   * @param forecast
   * @returns number Successful Response
   * @throws ApiError
   */
  public fetchCustomerDishOrdersOvenTimerFetchDishOrdersOvenTimerGet(
    fetchStartTime: string,
    fetchEndTime?: string,
    forecast: boolean = false,
  ): CancelablePromise<Record<string, Record<string, number>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fetch_dish_orders/oven_timer',
      query: {
        'fetch_start_time': fetchStartTime,
        'fetch_end_time': fetchEndTime,
        'forecast': forecast,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch Customer Dish Orders As Robot Dish Orders
   * Fetching the current dish orders in the remote queue and place them into Blue Chef
   *
   * Args:
   * fetch_start_time (`datetime`): The start time boundary of orders fetching.
   * fetch_end_time (`Optional[datetime]`): The end time boundary of orders fetching.
   * forecast (`bool`): Whether fetching the forecast orders from the remote server.
   * only_mc_recipes (`Optional[bool]`): Whether only fetch the MC recipes. Default to True.
   * filter_by_meal_type (`Optional[MealTypes`):
   * Set to only keep one meal type in the fetch result.
   * just_view_raw_customer_orders (`Optional[bool]`):
   * Set this to True if you just want to view the raw customer orders fetched from the
   * remote server but not passing them into Blue Chef dish order memory
   * just_view_robot_orders (`Optional[bool]`):
   * Set this to True if you only want to view robot orders converted from raw customer
   * orders but not place them into Blue Chef dish order memory.
   * rush_cook (`Optional[bool]`):
   * Whether to remove the delivering time in the customer dish orders so the number of bowls
   * to cook can reduce as much as possible and then schedule to cook as fast as possible.
   * overwrite_deadline_date_as (`Optional[date]`):
   * Overwrite the date in all fetched customer orders' delivering time to the provided date.
   * This is mainly for developers to practice cooking of multiple days dish orders in
   * one day.
   * overwrite_deadline_time_in_range_from (`Optional[time]`):
   * The start time (excluding date) range to modifying fetched customer orders'
   * delivering time.
   * This is mainly for developers to practice cooking of lunch and dinner service together.
   * overwrite_deadline_time_in_range_to (`Optional[time]`):
   * The end time (excluding date) range to modifying fetched customer orders'
   * delivering time.
   * This is mainly for developers to practice cooking of lunch and dinner service together.
   * overwrite_deadline_time_delta (`Optional[timedelta]`):
   * The relative amount of time delta to modify fetched customer orders' delivering time.
   * This is mainly for developers to practice cooking of lunch and dinner service together.
   *
   * Returns:
   * dish_orders (`List[DishOrder]`): List of the dish orders in queue
   * @param fetchStartTime
   * @param fetchEndTime
   * @param forecast
   * @param onlyMcRecipes
   * @param filterByMealType
   * @param justViewRawCustomerOrders
   * @param justViewRobotOrders
   * @param rushCook
   * @param overwriteDeadlineDateAs
   * @param overwriteDeadlineTimeInRangeFrom
   * @param overwriteDeadlineTimeInRangeTo
   * @param overwriteDeadlineTimeDelta
   * @returns DishOrder Successful Response
   * @throws ApiError
   */
  public fetchCustomerDishOrdersAsRobotDishOrdersFetchDishOrdersGet(
    fetchStartTime: string,
    fetchEndTime?: string,
    forecast: boolean = false,
    onlyMcRecipes: boolean = true,
    filterByMealType?: MealTypes,
    justViewRawCustomerOrders: boolean = false,
    justViewRobotOrders: boolean = false,
    rushCook: boolean = false,
    overwriteDeadlineDateAs?: string,
    overwriteDeadlineTimeInRangeFrom?: string,
    overwriteDeadlineTimeInRangeTo?: string,
    overwriteDeadlineTimeDelta?: number,
  ): CancelablePromise<Array<DishOrder>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fetch_dish_orders',
      query: {
        'fetch_start_time': fetchStartTime,
        'fetch_end_time': fetchEndTime,
        'forecast': forecast,
        'only_mc_recipes': onlyMcRecipes,
        'filter_by_meal_type': filterByMealType,
        'just_view_raw_customer_orders': justViewRawCustomerOrders,
        'just_view_robot_orders': justViewRobotOrders,
        'rush_cook': rushCook,
        'overwrite_deadline_date_as': overwriteDeadlineDateAs,
        'overwrite_deadline_time_in_range_from': overwriteDeadlineTimeInRangeFrom,
        'overwrite_deadline_time_in_range_to': overwriteDeadlineTimeInRangeTo,
        'overwrite_deadline_time_delta': overwriteDeadlineTimeDelta,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Robot Dish Orders Summary
   * Endpoint to summarize the robot bowl orders
   *
   * Args:
   * dish_orders_robot (`List[DishOrder]`): List of robot bowl orders.
   *
   * Returns:
   * None
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public robotDishOrdersSummaryDishOrdersSummaryPost(
    requestBody: Array<DishOrder>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dish_orders_summary',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Dish Orders
   * Fetching the current dish orders in the queue
   *
   * Returns:
   * dish_orders (`List[DishOrder]`): List of the dish orders in queue
   * @returns DishOrder Successful Response
   * @throws ApiError
   */
  public getDishOrdersDishOrdersGet(): CancelablePromise<Array<DishOrder>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dish_orders',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Remove All Dish Orders
   * Remove all the dish orders in the queue (reset to empty)
   *
   * Returns:
   * None
   * @returns any Successful Response
   * @throws ApiError
   */
  public removeAllDishOrdersAllDishOrdersDelete(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/all_dish_orders',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Customer To Robot Orders
   * Converts a list of customer dish orders to robot dish orders.
   *
   * Args:
   * dish_orders_customer (`List[DishOrderFrontEndTransaction]`): a list customer dish orders
   * only_mc_recipes (`Optional[bool]`): Whether only fetch the MC recipes. Default to True.
   *
   * Returns:
   * dish_orders_robot (`List[DishOrder]`): a list of robot dish orders
   * @param requestBody
   * @param onlyMcRecipes
   * @returns DishOrder Successful Response
   * @throws ApiError
   */
  public customerToRobotOrdersCuToRobotOrderPost(
    requestBody: Array<DishOrderFrontEndTransaction>,
    onlyMcRecipes: boolean = true,
  ): CancelablePromise<Array<DishOrder>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/cu_to_robot_order',
      query: {
        'only_mc_recipes': onlyMcRecipes,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

}
