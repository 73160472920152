/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Container } from '../models/Container';
import type { ContainerType } from '../models/ContainerType';
import type { ContainerTypes } from '../models/ContainerTypes';
import type { DishOrder } from '../models/DishOrder';
import type { IngredientAmount } from '../models/IngredientAmount';
import type { MEPAdjustmentModes } from '../models/MEPAdjustmentModes';
import type { MEPPresetsGroupingMode } from '../models/MEPPresetsGroupingMode';
import type { ScheduleBuildConfig } from '../models/ScheduleBuildConfig';
import type { ShelfConfig } from '../models/ShelfConfig';
import type { ShelfSlot } from '../models/ShelfSlot';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShelfService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add New Container
   * Adds container to the shelf or to the kitchen.
   *
   * Sets the `depth_counter` of the container to be:
   * 1) None if we add the container only to kitchen but not to shelf;
   * 2) 0 if we add the container to shelf from front;
   * 3) a large number like 999 if we add the container to shelf from back.
   *
   * Args:
   * container(`libypc.pydantic_models.shelf.Container`): the container to add
   *
   * Returns:
   * the added container Pydantic object
   * @param requestBody
   * @returns Container Successful Response
   * @throws ApiError
   */
  public addNewContainerAddNewContainerPost(
    requestBody: Container,
  ): CancelablePromise<Container> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/add_new_container',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Find Container By Name
   * Finds container by container name.
   *
   * Args:
   * container_name(`str`): name of the container used to find the container
   *
   * Returns:
   * the container Pydantic object
   * @param containerName
   * @returns Container Successful Response
   * @throws ApiError
   */
  public findContainerByNameFindContainerByNameGet(
    containerName: string,
  ): CancelablePromise<Container> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/find_container_by_name',
      query: {
        'container_name': containerName,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Find Container By Ing Id
   * Finds containers by the id of ingredients inside.
   *
   * Args:
   * ing_id(`int`): the ingredient ID in a container
   *
   * Returns:
   * the list of container Pydantic objects
   * @param ingId
   * @returns Container Successful Response
   * @throws ApiError
   */
  public findContainerByIngIdFindContainerByIngIdGet(
    ingId: number,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/find_container_by_ing_id',
      query: {
        'ing_id': ingId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Find Container By Pose
   * Finds containers by the pose string.
   *
   * Args:
   * pose_str(`str`): the pose string of the target container
   *
   * Returns:
   * the list of container Pydantic objects
   * @param poseStr
   * @returns Container Successful Response
   * @throws ApiError
   */
  public findContainerByPoseFindContainerByPoseGet(
    poseStr: string,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/find_container_by_pose',
      query: {
        'pose_str': poseStr,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Container Info
   * Updates container information on the shelf.
   *
   * Args:
   * container(`libypc.pydantic_models.shelf.Container`): the container to update
   *
   * Returns:
   * the updated container Pydantic objects
   * @param requestBody
   * @returns Container Successful Response
   * @throws ApiError
   */
  public updateContainerInfoUpdateContainerInfoPut(
    requestBody: Container,
  ): CancelablePromise<Container> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/update_container_info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Container By Name
   * Removes container from kitchen or from shelf.
   *
   * Args:
   * container_name(`str`): the name of container to be removed
   *
   * Returns:
   * True if the container is deleted and False otherwise.
   * @param containerName
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public removeContainerByNameRemoveContainerByNameDelete(
    containerName: string,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/remove_container_by_name',
      query: {
        'container_name': containerName,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sync Containers
   * Sync up containers in kitchen and/or on shelf.
   *
   * Args:
   * containers (`List[Container]`): The list of containers desired to sync to.
   *
   * Returns:
   * None
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public syncContainersSyncContainersPost(
    requestBody: Array<Container>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sync_containers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Slot State
   * Fetches slot state on the shelf
   *
   * Args:
   * shelf_id(`int`): shelf id of the slot
   * row_id(`int`): row id of the slot
   * col_id(`int`): column id of the slot
   *
   * Returns:
   * slot(`ShelfSlot`): an object contains shelf id, row id, column id, and containers on it
   * @param shelfId
   * @param rowId
   * @param colId
   * @returns ShelfSlot Successful Response
   * @throws ApiError
   */
  public getSlotStateGetSlotStateGet(
    shelfId: number,
    rowId: number,
    colId: number,
  ): CancelablePromise<ShelfSlot> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_slot_state',
      query: {
        'shelf_id': shelfId,
        'row_id': rowId,
        'col_id': colId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Put Move Grab Container On Shelf
   * Puts or moves an existing container to a given slot, or grabs or removes it from shelf.
   *
   * To put or move the container on shelf, sets the `depth_counter` of the container to be:
   * 1) `0` if we add the container to shelf from front;
   * 2) a large number like `999` if we add the container to shelf from back.
   *
   * To remove the container from shelf, sets the pose of the container to a place other than slot,
   * such as `water_station`. If there is no need to specify a location, sets the pose as `nowhere`.
   *
   * Args:
   * container(`libypc.pydantic_models.shelf.Container`): the container to be added to the slot
   *
   * Returns:
   * slot(`ShelfSlot`): an object contains shelf id, row id, column id, and containers on it
   * @param requestBody
   * @returns ShelfSlot Successful Response
   * @throws ApiError
   */
  public putMoveGrabContainerOnShelfPutMoveGrabContainerOnShelfPost(
    requestBody: Container,
  ): CancelablePromise<ShelfSlot> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/put_move_grab_container_on_shelf',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Find An Available Empty Container
   * Finds an available empty and clean container on the shelf
   *
   * container_type (`Optional[ContainerTypes]`): The container type you would like to search for.
   *
   * Returns:
   * `Container`: An available container on the shelf
   * @param containerType
   * @returns Container Successful Response
   * @throws ApiError
   */
  public findAnAvailableEmptyContainerFindAnAvailableEmptyContainerGet(
    containerType?: ContainerTypes,
  ): CancelablePromise<Container> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/find_an_available_empty_container',
      query: {
        'container_type': containerType,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Find Available Slots
   * Finds all available slots on the shelf
   *
   * Returns:
   * available_slots(`dict[list]`): all available slots on the main shelf and MEP shelf
   * @returns ShelfSlot Successful Response
   * @throws ApiError
   */
  public findAvailableSlotsFindAvailableSlotsGet(): CancelablePromise<Record<string, Array<ShelfSlot>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/find_available_slots',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Get Current Shelf Status
   * Gets current shelf arrangement status.
   *
   * Args:
   * shelf_id('int'): id of the shelf to get status
   *
   * Returns:
   * (`List[Container]`): a list of all containers on the given shelf
   * @param shelfId
   * @returns Container Successful Response
   * @throws ApiError
   */
  public getCurrentShelfStatusGetCurrentShelfStatusGet(
    shelfId: number,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_current_shelf_status',
      query: {
        'shelf_id': shelfId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Shelf Configs
   * Gets all available shelf configs.
   *
   * Returns:
   * (`List[ShelfConfig]`): return a list of shelf configs
   * @returns ShelfConfig Successful Response
   * @throws ApiError
   */
  public getShelfConfigsGetShelfConfigsGet(): CancelablePromise<Array<ShelfConfig>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_shelf_configs',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Remove All Containers On A Shelf
   * Remove all containers on a given shelf.
   *
   * Args:
   * shelf_id (`int`): The shelf ID to remove all containers
   *
   * Returns:
   * list of containers that been removed
   * @param shelfId
   * @returns Container Successful Response
   * @throws ApiError
   */
  public removeAllContainersOnAShelfEmptyShelfDelete(
    shelfId: number,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/empty_shelf',
      query: {
        'shelf_id': shelfId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Target Shelf
   * Generates container placement plan by putting the same ingredient in the same slot.
   *
   * Args:
   * dish_orders (`Optional[List[DishOrder]]`): The dish orders used to generate the container
   * placement plan. If not specified, we will get dish orders from the ordering module.
   * shelf_id (`Optional[int]`): The shelf ID to get target containers arrangement. Get
   * target arrangement for all shelves if not provided.
   * modify_db(`Optional[bool]`): Whether to modify the database to match the target shelf
   * arrangement as the output of this endpoint
   * use_mep ('Optional[bool]'): Whether is using MEP for cooking. Default to False.
   * use_mep_mode ('Optional[MEPAdjustmentModes]'):
   * ONLY_PREP, ONLY_PEAK, or PREP_AND_PEAK. Default to ONLY_PEAK.
   * mep_presets_mode ('Optional[MEPPresetsGroupingMode]'):
   * When using MEP, whether and how to use MEP presets stored in database.
   * Default to use as it.
   *
   * Returns:
   * containers(`List[Container]`): a list of Container object to prepare
   * @param shelfId
   * @param modifyDb
   * @param useMep
   * @param useMepMode
   * @param mepPresetsMode
   * @param requestBody
   * @returns Container Successful Response
   * @throws ApiError
   */
  public getTargetShelfGetTargetShelfIngBasedPost(
    shelfId?: number,
    modifyDb: boolean = false,
    useMep: boolean = false,
    useMepMode?: MEPAdjustmentModes,
    mepPresetsMode?: MEPPresetsGroupingMode,
    requestBody?: Array<DishOrder>,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/get_target_shelf/ing_based',
      query: {
        'shelf_id': shelfId,
        'modify_db': modifyDb,
        'use_mep': useMep,
        'use_mep_mode': useMepMode,
        'mep_presets_mode': mepPresetsMode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Target Shelf By Ing Based Mep
   * Generates container placement plan by putting the same ingredient in the same slot.
   *
   * Args:
   * dish_orders (`Optional[List[DishOrder]]`): The dish orders used to generate the container
   * placement plan. If not specified, we will get dish orders from the ordering module.
   * shelf_id (`Optional[int]`): The shelf ID to get target containers arrangement. Get
   * target arrangement for all shelves if not provided.
   * modify_db (`Optional[bool]`): Whether to modify the database to match the target
   * shelf arrangement as the output of this endpoint
   * use_mep_mode ('Optional[MEPAdjustmentModes]'):
   * ONLY_PREP, ONLY_PEAK, or PREP_AND_PEAK. Default to ONLY_PEAK.
   * mep_presets_mode ('Optional[MEPPresetsGroupingMode]'):
   * When using MEP, whether and how to use MEP presets stored in database.
   * Default to use as it.
   *
   * Returns:
   * containers(`List[Container]`): a list of Container object to prepare
   * @param shelfId
   * @param modifyDb
   * @param useMepMode
   * @param mepPresetsMode
   * @param requestBody
   * @returns Container Successful Response
   * @throws ApiError
   */
  public getTargetShelfByIngBasedMepGetTargetShelfIngBasedMepPost(
    shelfId?: number,
    modifyDb: boolean = false,
    useMepMode?: MEPAdjustmentModes,
    mepPresetsMode?: MEPPresetsGroupingMode,
    requestBody?: Array<DishOrder>,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/get_target_shelf/ing_based_mep',
      query: {
        'shelf_id': shelfId,
        'modify_db': modifyDb,
        'use_mep_mode': useMepMode,
        'mep_presets_mode': mepPresetsMode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Target Shelf By Schedule
   * Generates container placement plan by putting ingredients on slot based on the schedule.
   * Order of placement: 1. top-down 2. left to right 3. outside to inside
   *
   * Args:
   * reconstruct_config (`ScheduleBuildConfig`):
   * the schedule building configuration pydantic model
   * shelf_id (`Optional[int]`): The shelf ID to get target containers arrangement. Get
   * target arrangement for all shelves if not provided.
   * modify_db (`Optional[bool]`): Whether to modify the database to match the target shelf
   * arrangement as the output of this endpoint
   *
   * Returns:
   * containers (`List[Container]`): a list of Container object to prepare
   * @param requestBody
   * @param shelfId
   * @param modifyDb
   * @returns Container Successful Response
   * @throws ApiError
   */
  public getTargetShelfByScheduleGetTargetShelfScheduleBasedPost(
    requestBody: ScheduleBuildConfig,
    shelfId?: number,
    modifyDb: boolean = false,
  ): CancelablePromise<Array<Container>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/get_target_shelf/schedule_based',
      query: {
        'shelf_id': shelfId,
        'modify_db': modifyDb,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Choose Container Type
   * Figure out the type of container to use for a set of ingredients and amounts it needs to hold
   *
   * Args:
   * ing_contents (`List[IngredientAmount]`):
   * The list ingredient names, ids and their amounts.
   * oxo_max_holding_grams(`Optional[int]`): The maximum capacity of the oxo container in grams.
   *
   * Returns:
   * A container type to use for the given list of ingredient steps
   * @param requestBody
   * @param oxoMaxHoldingGrams
   * @returns ContainerType Successful Response
   * @throws ApiError
   */
  public chooseContainerTypeChooseContainerTypePost(
    requestBody: Array<IngredientAmount>,
    oxoMaxHoldingGrams: number = 100,
  ): CancelablePromise<ContainerType> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/choose_container_type',
      query: {
        'oxo_max_holding_grams': oxoMaxHoldingGrams,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Find Target Mep Slot
   * Find and assign a slot to park a given container of a dish
   *
   * Args:
   * container (`Container`): The container data looking to park on shelf
   *
   * Returns:
   * allocated_slot(`str`): The slot name can park the given container type
   * @param requestBody
   * @returns string Successful Response
   * @throws ApiError
   */
  public findTargetMepSlotFindTargetMepSlotPost(
    requestBody: Container,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/find_target_mep_slot',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Download Current Shelf Status Plain Text
   * Generate the shelf state in plain text format which is easy to print
   *
   * Args:
   * shelf_id(`Optional[int]`): The shelf ID to print
   *
   * Returns:
   * None
   * @param shelfId
   * @returns any Successful Response
   * @throws ApiError
   */
  public downloadCurrentShelfStatusPlainTextGetCurrentShelfStatusPlainTextGet(
    shelfId?: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_current_shelf_status/plain_text',
      query: {
        'shelf_id': shelfId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

}
