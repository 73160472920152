/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Step type names and the data class binding
 */
export enum StepType {
  COOKING = 'cooking',
  INGREDIENT = 'ingredient',
  OVEN = 'oven',
  HUMAN = 'human',
  CHILD_RECIPE = 'child_recipe',
}
