import { FC, useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import jspPlatformApi from "client/portals";
import { UserRead } from "client/jspPlatformExperiment";

interface UserProfileContainerProps {}

const UserProfileContainer: FC<UserProfileContainerProps> = () => {
  const [fullName, setFullName] = useState<string>("");

  const auth = useAuth();

  /**
   * The effect to update user info when loading the page for the first time
   */
  useEffect(() => {
    if (auth.user === undefined) {
      jspPlatformApi()
        .user.getUserAccountUserAccountUserIdGet({ userId: 0 })
        .then((userProfile: UserRead) => {
          auth.refreshUser(userProfile);
          setFullName(userProfile.full_name ?? "Unknown");
        });
    } else {
      setFullName(auth.user?.full_name ?? "Unknown");
    }
  }, [auth, fullName]);

  return <h1>{fullName}</h1>;
};

export default UserProfileContainer;
