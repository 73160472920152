import { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import routes from "models/routes";
import useAuth from "hooks/useAuth";

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  // console.log(auth);

  if (!auth.accessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};

export default AuthGuard;
