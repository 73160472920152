import { FC, useCallback, useEffect, useReducer, useState } from "react";
import MenuBasicInfoEditSection from "components/menu/ui/MenuBasicInfoEditSection";
import EditSection from "components/common/ui/EditSection";
import MenuEditActionButtons from "components/menu/ui/MenuEditActionButtons";
import MenuContentsEditSection from "components/menu/ui/MenuContentsEditSection";
import {
  defaultMenuSectionDisplay,
  MenuContentActions,
  menuContentsReducer,
} from "models/menu/menuContentsReducer";
import { v4 as uuidv4 } from "uuid";
import {
  ApiError,
  FoodItemKey,
  Menu,
  MenuCreateDeep,
  MenuItemCreate,
  MenuItemCreateDeep,
  MenuItemUpdateDeep,
  type MenuSectionCreateDeep,
  MenuSectionUpdateDeep,
  MenuUpdateDeep,
} from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { useAppSelector } from "hooks/useReduxStore";
import editingMenuSelector from "store/selectors/menu/menuDetailSelector";
import { isErrorResponseBodyType } from "models/utils/apiUtils";

interface MenuEditFormsProps {
  foodItemOptions: FoodItemKey[];
}

const MenuEditForms: FC<MenuEditFormsProps> = ({ foodItemOptions }) => {
  const editingMenuData = useAppSelector(editingMenuSelector);

  const [menuBasicInfo, setMenuBasicInfo] = useState<Omit<Menu, "sections">>({
    id: 0,
    publish_prior_to_serving_time: "P2D",
    cap_finalization_prior_to_serving_time: "P1D",
  });
  const [menuContents, dispatchMenuContents] = useReducer(menuContentsReducer, {
    [uuidv4()]: defaultMenuSectionDisplay(),
  });

  /**
   * Use effect to sync editing menu data
   */
  useEffect(() => {
    if (editingMenuData) {
      setMenuBasicInfo(editingMenuData);
      dispatchMenuContents({
        type: MenuContentActions.LOAD_FROM_MENU_DATA,
        menuData: editingMenuData,
      });
    }
  }, [editingMenuData]);

  /**
   * Save as new menu
   */
  const handleCreateMenu = useCallback(() => {
    const toCreateMenu: MenuCreateDeep = {
      ...menuBasicInfo,
      sections: Object.values(menuContents).map(
        (displaySection): MenuSectionCreateDeep => ({
          name: displaySection.name,
          items: Object.values(displaySection.items).map(
            (displayItem): MenuItemCreateDeep => ({
              ...displayItem,
              sides: Object.values(displayItem.sides).map(
                (displaySide): MenuItemCreate => ({
                  ...displaySide,
                }),
              ),
            }),
          ),
        }),
      ),
    };
    // Call Jasper platform API to save new menu
    jspPlatformApi()
      .menu.deepCreateMenuMenuMealPost({ requestBody: toCreateMenu })
      .then(() => {
        // TODO: Push success message
        // console.log("Saved success: ", toCreateMenu);
      })
      .catch((reason: ApiError) => {
        if (isErrorResponseBodyType(reason.body)) {
          alert(
            `Error saving menu: ${toCreateMenu.name}, ${reason.body.detail}`,
          );
        }
      });
  }, [menuBasicInfo, menuContents]);

  /**
   * Delete menu
   */
  const handleDeleteMenu = useCallback(
    (editingMenuId: number) => () => {
      if (editingMenuId !== 0) {
        jspPlatformApi()
          .menu.deleteMenuMenuMealMenuIdDelete({ menuId: editingMenuId })
          .then(() => {
            // TODO: Push success message
            // console.log("Delete menu success: ", editingMenuId);
          })
          .catch((reason: ApiError) => {
            alert(`Error delete menu ID: ${editingMenuId}`);
          });
      }
    },
    [],
  );

  /**
   * Update menu
   */
  const handleUpdateMenu = useCallback(
    (editingMenuId: number) => {
      if (editingMenuId === 0) {
        return handleCreateMenu;
      }
      return () => {
        const toDeepUpdateMenu: MenuUpdateDeep = {
          ...menuBasicInfo,
          sections: Object.values(menuContents).map(
            (displaySection): MenuSectionUpdateDeep => ({
              ...displaySection,
              items: Object.values(displaySection.items).map(
                (displayItem): MenuItemUpdateDeep => ({
                  ...displayItem,
                  sides: Object.values(displayItem.sides).map(
                    (displaySide): MenuItemUpdateDeep => ({
                      ...displaySide,
                      sides: null,
                    }),
                  ),
                }),
              ),
            }),
          ),
        };
        // Update the menu basic information
        jspPlatformApi()
          .menu.deepUpdateMenuMenuMealMenuIdPut({
            menuId: editingMenuId,
            requestBody: toDeepUpdateMenu,
          })
          .then(() => {
            // TODO: push success message
            //  console.log("Deep update menu success");
          })
          .catch((reason: ApiError) => {
            alert(`Error deep update menu: ${menuBasicInfo.name}`);
          });
      };
    },
    [handleCreateMenu, menuBasicInfo, menuContents],
  );

  return (
    <>
      <EditSection>
        <MenuEditActionButtons
          onCreate={handleCreateMenu}
          onUpdate={handleUpdateMenu(editingMenuData ? editingMenuData.id : 0)}
          onDelete={handleDeleteMenu(editingMenuData ? editingMenuData.id : 0)}
        />
      </EditSection>
      <EditSection header="Basic Information">
        <MenuBasicInfoEditSection
          menuBasicInfo={menuBasicInfo}
          setMenuBasicInfo={setMenuBasicInfo}
        />
      </EditSection>
      <EditSection header="Menu Items">
        <MenuContentsEditSection
          menuContents={menuContents}
          dispatchMenuContents={dispatchMenuContents}
          foodItemOptions={foodItemOptions}
        />
      </EditSection>
      <EditSection>
        <MenuEditActionButtons
          onCreate={handleCreateMenu}
          onUpdate={handleUpdateMenu(editingMenuData ? editingMenuData.id : 0)}
          onDelete={handleDeleteMenu(editingMenuData ? editingMenuData.id : 0)}
        />
      </EditSection>
    </>
  );
};

export default MenuEditForms;
