/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Child recipe categories
 */
export enum ChildRecipeCategories {
  STATIC = 'static',
  RUNTIME_HERB = 'runtime herb mixing',
  RUNTIME_MEP_PREP = 'mise en place preparation',
}
