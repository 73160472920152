import { appConfig } from "models/appConfig";
import { BlueChefAPIClient } from "client/blueChef";
import { Interceptors } from "client/jspPlatformExperiment/core/OpenAPI";
import { AxiosResponse } from "axios";
import { JasperPlatformAPIClient } from "./jspPlatformExperiment";

let apiInstance: JasperPlatformAPIClient;

type ErrHandlerFunc = (err: AxiosResponse) => void;

const errorHandlers: ErrHandlerFunc[] = [];

export const addApiErrHandler = (handler: ErrHandlerFunc) => {
  errorHandlers.push(handler);
};

const responseInterceptor = new Interceptors<AxiosResponse>();
responseInterceptor.use((response) => {
  errorHandlers.forEach((func) => func(response));
  return response;
});

const jspPlatformApi = (): JasperPlatformAPIClient => {
  const currToken = localStorage.getItem("accessToken");
  const prevToken = localStorage.getItem("prevAccessToken");

  if (prevToken === currToken && apiInstance) {
    return apiInstance;
  }

  // console.log(`Create new jasper platform client. ${currToken}|${prevToken}`);
  if (currToken !== null && currToken !== undefined) {
    localStorage.setItem("prevAccessToken", currToken);
  }
  apiInstance = new JasperPlatformAPIClient({
    BASE: `${appConfig.jspServerUrlRoot}`,
    TOKEN: currToken ?? "",
    WITH_CREDENTIALS: true,
    interceptors: {
      request: new Interceptors(),
      response: responseInterceptor,
    },
  });

  // console.log(apiInstance.request.config.TOKEN);

  return apiInstance;
};

/**
 * Temporary blue chef client
 */
export const blueChefAPI = new BlueChefAPIClient({
  BASE: `${appConfig.blueChefUrlRoot}`,
});

export default jspPlatformApi;
