import { FC } from "react";
import styled from "styled-components";

interface NutrientLabelRowProps {
  primary?: boolean;
  subCategory?: boolean;
  displayDailyValue?: boolean;
  fontSize?: number;
}

interface NutrientRowProps extends NutrientLabelRowProps {
  nutrientName: string;
  value?: number;
  valueUnit?: string;
  dailyValue?: number;
}

const NutrientLabelRow = styled.div<NutrientLabelRowProps>`
  padding: 1px 1px 1px ${({ subCategory }) => (subCategory ? 1 : 0)}em;
  font-weight: ${({ primary }) => (primary ? 700 : 400)};
  font-size: ${({ fontSize }) => fontSize || 14}pt;
  border-top: ${({ subCategory }) => (subCategory ? 0 : 1)}px solid black;
  position: relative;

  .dailyValue {
    display: inline;
    position: absolute;
    right: 0;
  }
`;

const NutrientRow: FC<NutrientRowProps> = ({
  nutrientName,
  value,
  valueUnit,
  primary,
  subCategory,
  displayDailyValue,
  dailyValue,
  fontSize,
}) => {
  return (
    <NutrientLabelRow
      primary={primary}
      subCategory={subCategory}
      displayDailyValue={displayDailyValue}
      fontSize={fontSize}
    >
      {nutrientName} {value || value === 0 ? Math.round(value) : `- `}{" "}
      {valueUnit}
      {displayDailyValue ? (
        <div className="dailyValue">
          {dailyValue || dailyValue === 0 ? Math.round(dailyValue) : `- `} %
        </div>
      ) : null}
    </NutrientLabelRow>
  );
};

export default NutrientRow;
