import { FC } from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ButtonProps } from "@mui/material";
import Routes from "models/routes";

export interface NavItemProps {
  displayName: string;
  path: Routes;
}

interface NavButtonProps extends NavItemProps, ButtonProps {}

export const StyledNavButton = styled(Button)`
  color: #fff;

  &:hover {
    background: #fff;
    color: #ffcf84;
  }
` as typeof Button;

const NavButton: FC<NavButtonProps> = ({ displayName, path, ...props }) => {
  return (
    <StyledNavButton component={Link} to={path} {...props}>
      {displayName}
    </StyledNavButton>
  );
};

export default NavButton;
