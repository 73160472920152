import { RootState } from "store/index";

const foodPurchasingGuide = (state: RootState) =>
  state.foodPurchasingGuide.data;

const foodPurchasingGuideIsLoading = (state: RootState) =>
  state.foodPurchasingGuide.isLoading;

const foodPurchasingGuideError = (state: RootState) =>
  state.foodPurchasingGuide.error;

export default {
  foodPurchasingGuide,
  foodPurchasingGuideIsLoading,
  foodPurchasingGuideError,
};
