import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const StyledIngredientEditSection = styled(Container)`
  padding: 18px 0;
`;

const StyledIngredientEditSectionHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 300;
  padding: 8px 0 8px 0;
  //color: rgb(120, 203, 255);
  //background: rgb(120, 203, 255);
  color: #ccc;
  //background: #fff;
`;

interface EditSectionProps extends PropsWithChildren {
  header?: string;
}

const EditSection: FC<EditSectionProps> = ({ header, children }) => {
  return (
    <StyledIngredientEditSection>
      {!!header && (
        <StyledIngredientEditSectionHeader>
          {header}
        </StyledIngredientEditSectionHeader>
      )}
      {children}
    </StyledIngredientEditSection>
  );
};

export default EditSection;
