import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  type GetStockDetailOfAfoodInventoryStockFoodIdDetailGetData,
  StockReplenishment,
} from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

interface FoodStockDetailStateProps {
  /** Return value of food stock data from the Jasper Platform API call */
  foodStockRecords: StockReplenishment[] | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: FoodStockDetailStateProps = {
  foodStockRecords: null,
  isLoading: false,
  error: null,
};

const fetchFoodStockDetail = createAsyncThunk(
  "foodStockDetail/fetchFoodStockDetail",
  async (
    input: GetStockDetailOfAfoodInventoryStockFoodIdDetailGetData,
    { signal, rejectWithValue },
  ) => {
    try {
      const recipeFoodStockData =
        await jspPlatformApi().inventory.getStockDetailOfAFoodInventoryStockFoodIdDetailGet(
          input,
        );
      return recipeFoodStockData;
    } catch (error) {
      let errMsg: string;
      if (isApiError(error) && isErrorResponseBodyType(error.body)) {
        errMsg = error.body.detail;
      } else {
        errMsg = "Unknown error!";
      }
      return rejectWithValue(errMsg);
    }
  },
);

const updateFoodStockDetail = (
  state: FoodStockDetailStateProps,
  action: PayloadAction<StockReplenishment[] | null>,
) => {
  return {
    ...state,
    foodStockRecords: action.payload ?? [],
  };
};

export const foodStockDetailSlice = createSlice({
  name: "foodStockDetailSlice",
  initialState,
  reducers: {
    setFoodStockDetail: updateFoodStockDetail,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoodStockDetail.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchFoodStockDetail.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodStockDetail(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchFoodStockDetail.rejected, (state, action) => {
      return { ...state, isLoading: false, error: action.payload as string };
    });
  },
});

export const actions = {
  ...foodStockDetailSlice.actions,
  fetchFoodStockDetail,
};

export default foodStockDetailSlice.reducer;
