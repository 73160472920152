import { FC, MouseEventHandler, useState } from "react";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { NavExpandingMenuProps } from "components/navBar/ui/NavExpandingMenu";
import NavListButton, {
  StyledNavListButton,
} from "components/navBar/ui/NavListButton";
import { useTranslation } from "react-i18next";

interface NavDrawerNestedListProps extends NavExpandingMenuProps {
  onClick: MouseEventHandler;
}

const NavDrawerNestedList: FC<NavDrawerNestedListProps> = ({
  menuDisplayName,
  options,
  onClick,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavListButton onClick={handleClick}>
        <ListItemText primary={menuDisplayName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledNavListButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List onClick={onClick} component="div" disablePadding>
          {options.map((option) => (
            <NavListButton
              key={option.path}
              displayName={t(option.displayName)}
              path={option.path}
              sx={{ pl: 4 }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NavDrawerNestedList;
