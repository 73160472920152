import { FC, useCallback, useState } from "react";
import { Container } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import EditSection from "components/common/ui/EditSection";
import CommonButton from "components/common/ui/CommonButton";
import PortionOrderInputForm from "components/inventory/ui/purchasing/portionOrders/PortionOrderInputForm";
import FoodPurchasingGuideTable from "components/inventory/ui/purchasing/FoodPurchasingGuideTable";

export enum PurchaseInputTypes {
  portionOrders = "Portion Orders",
  menus = "Menus",
  dateRangeOfMenus = "Date Range of Menus",
}

interface PurchaseToolPageProps {}

const FoodPurchasingPage: FC<PurchaseToolPageProps> = () => {
  const [selectedInput, setSelectedInput] = useState(
    PurchaseInputTypes.portionOrders,
  );

  const changeSelectInput = useCallback(
    (inType: PurchaseInputTypes) => () => {
      setSelectedInput(inType);
    },
    [],
  );

  const getSelectedTool = useCallback((inType: PurchaseInputTypes) => {
    if (inType === PurchaseInputTypes.portionOrders) {
      return <PortionOrderInputForm />;
    } else if (inType === PurchaseInputTypes.menus) {
      return <p>Menus coming soon ...</p>;
    } else if (inType === PurchaseInputTypes.dateRangeOfMenus) {
      return <p>Date range of menus coming soon ...</p>;
    }
    return <p>Unknown, not supported input type</p>;
  }, []);

  return (
    <Container>
      <BottomSpacedPageHeader>Purchasing Tools</BottomSpacedPageHeader>
      <EditSection header="Get purchasing list from">
        {/* Purchase input type action buttons*/}
        {Object.values(PurchaseInputTypes).map((inType) => (
          <CommonButton
            key={inType}
            text={inType}
            onClick={changeSelectInput(inType)}
            positive={selectedInput === inType ? "true" : undefined}
          />
        ))}
      </EditSection>
      <EditSection>
        {/* Show selected purchase list generation tools*/}
        {getSelectedTool(selectedInput)}
      </EditSection>
      <EditSection header="Purchasing list">
        <FoodPurchasingGuideTable />
      </EditSection>
    </Container>
  );
};

export default FoodPurchasingPage;
