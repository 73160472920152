import { FC, useEffect } from "react";
import { Container } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import { useParams } from "react-router-dom";
import EditSection from "components/common/ui/EditSection";
import { useAppDispatch } from "hooks/useReduxStore";
import { actions } from "store/slices/foodItem/foodItemDetailSlice";
import FoodItemBasicInfoViewSection from "components/food/ui/common/FoodItemBasicInfoViewSection";
import FoodSupplierViewSection from "components/food/ui/common/FoodSupplierViewSection";
import FoodStockRecordsViewSection from "components/inventory/ui/stockDetails/FoodStockRecordsViewSection";

interface FoodStockDetailPageProps {}

const FoodStockDetailPage: FC<FoodStockDetailPageProps> = () => {
  const { foodId } = useParams();
  const dispatch = useAppDispatch();

  /**
   * Use effect to load the food item information to view
   */
  useEffect(() => {
    if (foodId) {
      dispatch(actions.fetchFoodItemDetail({ foodId: Number(foodId) }));
    }
  }, [dispatch, foodId]);

  /**
   * Clear food item details on unmount
   */
  useEffect(() => {
    return () => {
      dispatch(actions.setFoodItemDetail(null));
    };
  }, [dispatch]);

  return (
    <Container>
      <BottomSpacedPageHeader>Inventory Details</BottomSpacedPageHeader>
      <EditSection header="Food Information">
        {/* Food information*/}
        <FoodItemBasicInfoViewSection />
      </EditSection>
      <EditSection header="Supplier Records">
        {/* Associated supplier records*/}
        <FoodSupplierViewSection />
      </EditSection>
      <EditSection header="Stock Records">
        {/* Food stock breakdown*/}
        <FoodStockRecordsViewSection />
      </EditSection>
    </Container>
  );
};

export default FoodStockDetailPage;
