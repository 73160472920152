import { RootState } from "store/index";

const allDishPortionOrders = (state: RootState) =>
  state.dishPortionOrders.dishPortionOrders;

const selectADishPortionOrders = (entityUUID: string) => (state: RootState) =>
  state.dishPortionOrders.dishPortionOrders?.[entityUUID];

export default {
  allDishPortionOrders,
  selectADishPortionOrders,
};
