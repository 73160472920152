import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import styled from "styled-components";
import { FC } from "react";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { NavItemProps } from "components/navBar/ui/NavButton";

interface NavListButtonProps extends NavItemProps, ListItemButtonProps {}

export const StyledNavListButton = styled(ListItemButton)`
  text-align: left;
  color: black;

  &:hover {
    color: rgb(120, 203, 255);
  }
` as typeof ListItemButton;

const NavListButton: FC<NavListButtonProps> = ({
  displayName,
  path,
  ...props
}) => {
  return (
    <StyledNavListButton component={Link} to={path} {...props}>
      <ListItemText primary={displayName} />
    </StyledNavListButton>
  );
};

export default NavListButton;
