/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_ingredient_from_file_upload_ingredient_file_post } from '../models/Body_update_ingredient_from_file_upload_ingredient_file_post';
import type { DishOrder } from '../models/DishOrder';
import type { Ingredient } from '../models/Ingredient';
import type { IngredientToCheckOrPurchase } from '../models/IngredientToCheckOrPurchase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InventoryService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Ingredient Info
   * Fetch ingredient info
   *
   * Args:
   * ingredient_name (`str`): The ingredient name to fetch details.
   *
   * Returns:
   * The ingredient info.
   * @param ingredientName
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public getIngredientInfoIngredientGet(
    ingredientName: string,
  ): CancelablePromise<Ingredient> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ingredient',
      query: {
        'ingredient_name': ingredientName,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Or Replace Ingredient Info
   * Add or replace ingredient info
   *
   * Args:
   * ingredient (`Ingredient`): The ingredient info object.
   *
   * Returns:
   * The new or updated ingredient ID.
   * @param requestBody
   * @returns number Successful Response
   * @throws ApiError
   */
  public addOrReplaceIngredientInfoIngredientPut(
    requestBody: Ingredient,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ingredient',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Ingredient
   * Delete an ingredient
   *
   * Args:
   * ingredient_name (`str`): The ingredient name to delete.
   *
   * Returns:
   * The deleted ingredient info.
   * @param ingredientName
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public deleteIngredientIngredientDelete(
    ingredientName: string,
  ): CancelablePromise<Ingredient> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ingredient',
      query: {
        'ingredient_name': ingredientName,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ingredient Info From Id
   * Fetch ingredient info from its id
   *
   * Args:
   * ingredient_id (`int`): The ingredient id to fetch details.
   *
   * Returns:
   * The ingredient info.
   * @param ingredientId
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public getIngredientInfoFromIdIngredientFromIdGet(
    ingredientId: number,
  ): CancelablePromise<Ingredient> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ingredient_from_id',
      query: {
        'ingredient_id': ingredientId,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get A List Of Ingredients Info
   * Fetch a list of ingredient datas
   *
   * Args:
   * ingredient_names (`List[str]`): The list of ingredient names to fetch details.
   *
   * Returns:
   * A list of ingredients datas.
   * @param ingredientNames
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public getAListOfIngredientsInfoIngredientBatchGet(
    ingredientNames: Array<string>,
  ): CancelablePromise<Array<Ingredient>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ingredient/batch',
      query: {
        'ingredient_names': ingredientNames,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Or Replace List Of Ingredients Info
   * Add or replace a list of ingredients data
   *
   * Args:
   * ingredients (`List[Ingredient]`): The list of ingredients to add or update.
   *
   * Returns:
   * The new or updated ingredient IDs.
   * @param requestBody
   * @returns number Successful Response
   * @throws ApiError
   */
  public addOrReplaceListOfIngredientsInfoIngredientBatchPut(
    requestBody: Array<Ingredient>,
  ): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ingredient/batch',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sync Ingredients From Remote
   * Sync local ingredient database with a remote server
   *
   * Args:
   * remote_ip (`str`): The remote host IP.
   * remote_port (`Optional[int]`): The remote host port to use.
   *
   * Returns:
   * None
   * @param remoteIp
   * @param remotePort
   * @returns any Successful Response
   * @throws ApiError
   */
  public syncIngredientsFromRemoteSyncIngredientsFromRemoteGet(
    remoteIp: string,
    remotePort: number = 8000,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/sync_ingredients_from_remote',
      query: {
        'remote_ip': remoteIp,
        'remote_port': remotePort,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Update Ingredient From File
   * Chef upload ingredient file to update database
   *
   * Args:
   * ingredient_file: The ingredient file in Excel.
   *
   * Returns:
   * The updated/created recipe names and IDs.
   * @param formData
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public updateIngredientFromFileUploadIngredientFilePost(
    formData: Body_update_ingredient_from_file_upload_ingredient_file_post,
  ): CancelablePromise<Array<Ingredient>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/upload_ingredient_file',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ingredient Usage Breakdown
   * Get the ingredient usage breakdown
   *
   * Ingredient amount used by each recipe of dish in the dish order.
   *
   * Args:
   * dish_orders (`List[DishOrder]`): List of dish name - portion number pair.
   * overwrite_with_orders_in_memory (`bool`): Whether to use dish orders in memory as the input.
   *
   * Returns:
   * ing_breakdown_items (`dict`): The ingredient usage breakdown.
   * @param requestBody
   * @param overwriteWithOrdersInMemory
   * @returns any Successful Response
   * @throws ApiError
   */
  public getIngredientUsageBreakdownIngredientUsageBreakdownPost(
    requestBody: Array<DishOrder>,
    overwriteWithOrdersInMemory: boolean = false,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ingredient_usage_breakdown',
      query: {
        'overwrite_with_orders_in_memory': overwriteWithOrdersInMemory,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ingredient Usage Summary
   * Get the ingredient amount usage summary (an ingredient per item)
   *
   * Args:
   * dish_orders (`List[DishOrder]`): List of dish name - portion number pair.
   * overwrite_with_orders_in_memory (`bool`): Whether to use dish orders in memory as the input.
   *
   * Returns:
   * ing_usage (`dict`): The ingredient usage summary of each ingredient grouped by supplier id.
   * @param requestBody
   * @param overwriteWithOrdersInMemory
   * @returns any Successful Response
   * @throws ApiError
   */
  public getIngredientUsageSummaryIngredientUsageSummaryPost(
    requestBody: Array<DishOrder>,
    overwriteWithOrdersInMemory: boolean = false,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ingredient_usage_summary',
      query: {
        'overwrite_with_orders_in_memory': overwriteWithOrdersInMemory,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ingredient Purchase List
   * Get the ingredient purchase list (required ingredient amount - stocked ingredient amount)
   *
   * Args:
   * dish_orders (`List[DishOrder]`): List of dish name - portion number pair.
   * overwrite_with_orders_in_memory (`bool`): Whether to use dish orders in memory as the input.
   *
   * Returns:
   * ingredients_to_purchase (`dict`): The ingredient purchase list.
   * @param requestBody
   * @param overwriteWithOrdersInMemory
   * @returns any Successful Response
   * @throws ApiError
   */
  public getIngredientPurchaseListIngredientPurchaseListPost(
    requestBody: Array<DishOrder>,
    overwriteWithOrdersInMemory: boolean = false,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ingredient_purchase_list',
      query: {
        'overwrite_with_orders_in_memory': overwriteWithOrdersInMemory,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ingredient Purchase List Plain Text
   * This function returns the ingredient purchase list in plain text file
   *
   * Args:
   * dish_orders (`List[DishOrder]`): List of dish name - portion number pair.
   * with_usage (`bool`): Whether to include ingredient usage details in the list.
   * overwrite_with_orders_in_memory (`bool`):
   * Whether to use dish orders in memory as the input.
   *
   * Returns:
   * purchase_list (`StreamingResponse`): A plain text file that can download.
   * @param requestBody
   * @param withUsage
   * @param overwriteWithOrdersInMemory
   * @returns any Successful Response
   * @throws ApiError
   */
  public getIngredientPurchaseListPlainTextIngredientPurchaseListPlainTextPost(
    requestBody: Array<DishOrder>,
    withUsage: boolean = true,
    overwriteWithOrdersInMemory: boolean = false,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ingredient_purchase_list/plain_text',
      query: {
        'with_usage': withUsage,
        'overwrite_with_orders_in_memory': overwriteWithOrdersInMemory,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Change Ingredient Stock By Name
   * Adds or subtracts the stock for the given ingredient in database by ingredient name
   *
   * Args:
   * ingredient_name (`str`):
   * Ingredient name used for querying ingredient details and changing ingredient stock.
   * amount_to_change (`float`):
   * Positive for adding ingredient quantity in grams and negative for subtracting.
   *
   * Returns:
   * ing_update_info (`dict`): The ingredient updating details.
   * @param ingredientName
   * @param amountToChange
   * @returns any Successful Response
   * @throws ApiError
   */
  public changeIngredientStockByNameChangeIngredientStockByNamePost(
    ingredientName: string,
    amountToChange: number,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/change_ingredient_stock/by_name',
      query: {
        'ingredient_name': ingredientName,
        'amount_to_change': amountToChange,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Change Ingredient Stock By Code
   * Adds or subtracts the stock for the given ingredient in database by product code
   *
   * Args:
   * product_code (`str`):
   * Ingredient code used for querying ingredient details and changing ingredient stock.
   * amount_to_change (`float`):
   * Positive for adding ingredient quantity in grams and negative for subtracting.
   *
   * Returns:
   * ing_update_info (`dict`): The ingredient updating details.
   * @param productCode
   * @param amountToChange
   * @returns any Successful Response
   * @throws ApiError
   */
  public changeIngredientStockByCodeChangeIngredientStockByCodePost(
    productCode: string,
    amountToChange: number,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/change_ingredient_stock/by_code',
      query: {
        'product_code': productCode,
        'amount_to_change': amountToChange,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Or Reduce Ing Stock In Batch
   * Adds or reduces ingredient stock level in batch
   *
   * Args:
   * ing_stock_to_change_dict (`dict`):
   * The dictionary with ingredient names and stock to change in grams
   * (positive for adding and negative for reducing).
   *
   * Returns:
   * all_ing_update_info (`List[dict]`): The list of all ingredient info after updated.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public addOrReduceIngStockInBatchChangeIngredientStockBatchByNamePost(
    requestBody: Record<string, any>,
  ): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/change_ingredient_stock/batch/by_name',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reduce Daily Ingredient Stock
   * Reduces ingredient stock level for all dish orders within a given date
   *
   * Args:
   * order_date (`datetime`):
   * The date used to reduce all ingredient levels for daily dish orders.
   *
   * Returns:
   * ing_update_info (`dict`): The ingredient updating details.
   * @param orderDate
   * @returns any Successful Response
   * @throws ApiError
   */
  public reduceDailyIngredientStockReduceDailyIngStockPost(
    orderDate: string,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/reduce_daily_ing_stock',
      query: {
        'order_date': orderDate,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ing Checking And Purchase List
   * Check ingredient stocks for dish orders and menu within a given day range
   *
   * Args:
   * check_start_time (`datetime`): The start time boundary of dish orders to check ingredients.
   * check_end_time (`Optional[datetime]`):
   * The end time boundary of dish orders to check ingredients if no end time is provided,
   * the default is 3 hours after the check start time.
   * forecast (`bool`): Whether fetching the forecast orders from the remote server.
   * fetch_ing_on_menu (`bool`): Whether to fetch menu ingredients for checking.
   *
   * Returns:
   * all_ing_list (`List[IngredientToCheckOrPurchase]`):
   * The list of all ingredients to purchase or to check.
   * @param checkStartTime
   * @param checkEndTime
   * @param forecast
   * @param fetchIngOnMenu
   * @returns IngredientToCheckOrPurchase Successful Response
   * @throws ApiError
   */
  public getIngCheckingAndPurchaseListGetIngCheckingAndPurchaseListGet(
    checkStartTime: string,
    checkEndTime?: string,
    forecast: boolean = false,
    fetchIngOnMenu: boolean = false,
  ): CancelablePromise<Array<IngredientToCheckOrPurchase>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_ing_checking_and_purchase_list',
      query: {
        'check_start_time': checkStartTime,
        'check_end_time': checkEndTime,
        'forecast': forecast,
        'fetch_ing_on_menu': fetchIngOnMenu,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Ing Names On Menu
   * Gets all ingredient names for the menu across days.
   *
   * Args:
   * check_start_time (`datetime`): The start time boundary to check menu ingredients.
   * check_end_time (`Optional[datetime]`): The end time boundary to check menu ingredients
   * If no end time is provided, the default is 3 hours after the check start time.
   *
   * Returns:
   * all_ingredient_names (`List[str]`): All ingredient names for the dishes on the weekly menu.
   * @param checkStartTime
   * @param checkEndTime
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAllIngNamesOnMenuGetAllIngNamesOnMenuGet(
    checkStartTime: string,
    checkEndTime?: string,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get_all_ing_names_on_menu',
      query: {
        'check_start_time': checkStartTime,
        'check_end_time': checkEndTime,
      },
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ing Stock In Batch
   * Gets ingredient stocks with a list of ingredient names.
   *
   * Args:
   * ingredient_names (`Set[str]`):
   * A list of unique ingredient names used to get stock levels.
   *
   * Returns:
   * ing_stock_dict (`dict`): The ingredient stock level dictionary.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public getIngStockInBatchGetIngStockInBatchPost(
    requestBody: Array<string>,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/get_ing_stock_in_batch',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Overwrite Ing Stock In Batch By Name
   * Overwrites ingredient stock level in database in batch.
   *
   * Args:
   * ing_stock_to_overwrite_dict (`dict`):
   * The dictionary with ingredient names and stock to update in grams.
   *
   * Returns:
   * all_ing_update_info (`List[dict]`): The list of all ingredient info after updated.
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public overwriteIngStockInBatchByNameOverwriteIngStockBatchByNamePut(
    requestBody: Record<string, any>,
  ): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/overwrite_ing_stock/batch/by_name',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Ingredients
   * Fetch all the ingredient names
   *
   * Returns:
   * ingredient_list (`list[str]`): List of ingredient names
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAllIngredientsAllIngredientsGet(): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/all_ingredients',
      errors: {
        404: `Not found`,
      },
    });
  }

  /**
   * Get All Ingredients With Full Details
   * Fetch all the ingredients with full detail fields in them
   *
   * Returns:
   * ingredient_list (`list[Ingredient]`): List of ingredient models
   * @returns Ingredient Successful Response
   * @throws ApiError
   */
  public getAllIngredientsWithFullDetailsAllIngredientsFullDetailsGet(): CancelablePromise<Array<Ingredient>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/all_ingredients/full_details',
      errors: {
        404: `Not found`,
      },
    });
  }

}
