import { FC } from "react";
import { Container, Grid } from "@mui/material";
import RecipeListTable from "components/food/ui/recipeList/recipeListTable";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";

interface RecipeListPageProps {}

const RecipeListPage: FC<RecipeListPageProps> = () => {
  return (
    <Container>
      <BottomSpacedPageHeader>Recipes</BottomSpacedPageHeader>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RecipeListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default RecipeListPage;
