import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";
import {
  BodyGetPurchasingGuideFromPortionOrdersInventoryPurchasingFromPortionOrdersPost,
  PurchasingGuideItem,
} from "client/jspPlatformExperiment";

interface FoodPurchasingGuideStateProps {
  data: PurchasingGuideItem[] | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: FoodPurchasingGuideStateProps = {
  data: [],
  isLoading: false,
  error: null,
};

const fetchFoodPurchasingGuide = createAsyncThunk(
  "foodPurchasingGuide/fetchByDishPortionOrders",
  async (
    input: BodyGetPurchasingGuideFromPortionOrdersInventoryPurchasingFromPortionOrdersPost,
    { signal, rejectWithValue },
  ) => {
    try {
      const purchasingGuide =
        (await jspPlatformApi().inventory.getPurchasingGuideFromPortionOrdersInventoryPurchasingFromPortionOrdersPost(
          { requestBody: input },
        )) as PurchasingGuideItem[];
      return purchasingGuide;
    } catch (error) {
      let errMsg: string;
      if (isApiError(error) && isErrorResponseBodyType(error.body)) {
        errMsg = error.body.detail;
      } else {
        errMsg = "Unknown error!";
      }
      return rejectWithValue(errMsg);
    }
  },
);

const updateFoodPurchaingGuide = (
  state: FoodPurchasingGuideStateProps,
  action: PayloadAction<PurchasingGuideItem[] | null>,
) => {
  return {
    ...state,
    data: action.payload ?? [],
  };
};

export const foodPurchasingGuideSlice = createSlice({
  name: "foodPurchasingGuideSlice",
  initialState,
  reducers: {
    setFoodPurchasingGuide: updateFoodPurchaingGuide,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoodPurchasingGuide.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchFoodPurchasingGuide.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodPurchaingGuide(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchFoodPurchasingGuide.rejected, (state, action) => {
      return { ...state, isLoading: false, error: action.payload as string };
    });
  },
});

export const actions = {
  ...foodPurchasingGuideSlice.actions,
  fetchFoodPurchasingGuide,
};

export default foodPurchasingGuideSlice.reducer;
