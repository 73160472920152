import { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { actions } from "store/slices/inventory/foodStockDetailSlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import foodStockDetailSelector from "store/selectors/inventory/foodStockDetailSelector";
import { StockReplenishment } from "client/jspPlatformExperiment";
import StockRecordViewBox from "components/inventory/ui/stockDetails/StockRecordViewBox";

interface FoodStockRecordsViewSectionProps {}

const FoodStockRecordsViewSection: FC<
  FoodStockRecordsViewSectionProps
> = () => {
  const { foodId } = useParams();
  const dispatch = useAppDispatch();
  const displayingFoodStockRecords = useAppSelector(
    foodStockDetailSelector.foodStockRecords,
  );
  const isLoading = useAppSelector(foodStockDetailSelector.foodStockIsLoading);

  /**
   * Use effect to load the food inventory detail records to view
   */
  useEffect(() => {
    if (foodId) {
      dispatch(actions.fetchFoodStockDetail({ foodId: Number(foodId) }));
    }
  }, [dispatch, foodId]);

  return isLoading || !displayingFoodStockRecords ? (
    <div>Loading...</div>
  ) : (
    <Grid container spacing={1}>
      {displayingFoodStockRecords.map((stockRecord: StockReplenishment) => (
        <Grid key={stockRecord.id} container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <StockRecordViewBox stockRecord={stockRecord} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodStockRecordsViewSection;
