import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Menu } from "client/jspPlatformExperiment";

interface MenuDetailStateProps {
  /** Return value of menu data from the Jasper Platform API call */
  menu: Menu | null;
}

const initialState: MenuDetailStateProps = { menu: null };

export const menuDetailSlice = createSlice({
  name: "menuDetailSlice",
  initialState,
  reducers: {
    setMenuDetail: (state, action: PayloadAction<Menu | null>) => {
      return { ...state, menu: action.payload };
    },
  },
});

export const { setMenuDetail } = menuDetailSlice.actions;

export default menuDetailSlice.reducer;
