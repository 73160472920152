import React, { FC, Suspense } from "react";

import { Provider } from "react-redux";
import AppRouter from "./components/AppRouter";
import store from "./store";
import "./i18n";

interface AppProps {}

const App: FC<AppProps> = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <AppRouter />
      </Suspense>
    </Provider>
  );
};

export default App;
