import { Outlet } from "react-router-dom";
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { Container, FormControlLabel, FormGroup } from "@mui/material";
import { useAppDispatch } from "hooks/useReduxStore";
import { ApiError, RecipeFrontEndTransaction } from "client/blueChef";
import { setRecipeDetail } from "store/slices/recipe/recipeDetailSlice";
import useJspApiErrorHandler from "hooks/useJspApiErrorHandler";
import { blueChefAPI } from "client/portals";
import AutocompleteTextField from "../../common/ui/AutocompleteTextField";
import YesNoSwitch from "../../common/ui/YesNoSwitch";

interface RecipeViewLayoutProps {}

const RecipeViewLayoutContainer = styled(Container)`
  padding: 20px 20px;
`;

const RecipeViewLayout: FC<RecipeViewLayoutProps> = () => {
  const [displayRecipeName, setDisplayRecipeName] = useState("");
  const [displayRecipeData, setDisplayRecipeData] =
    useState<RecipeFrontEndTransaction>();
  const [recipeNameList, setRecipeNameList] = useState<Array<string>>([]);
  const [showDebugJson, setShowDebugJson] = useState(false);

  const dispatch = useAppDispatch();
  const apiErrorHandler = useJspApiErrorHandler();

  const handleChangeShowJson = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setShowDebugJson(event.target.checked);
    },
    [],
  );

  // The effect to update recipe name list when loading the page for the first time
  useEffect(() => {
    if (recipeNameList.length === 0) {
      blueChefAPI.recipe
        .getAllRecipesAllRecipesGet()
        .then((recipeNames) => {
          setRecipeNameList(recipeNames);
        })
        .catch((reason: ApiError) => {
          apiErrorHandler(reason, () => {
            setRecipeNameList([]);
          });
        });
    }
  }, [apiErrorHandler, recipeNameList]);

  // The effect to update display recipe data when the display recipe name changed
  useEffect(() => {
    if (displayRecipeName !== "" && displayRecipeName !== null) {
      blueChefAPI.recipe
        .getRecipeRecipeGet(displayRecipeName)
        .then((recipeData) => {
          setDisplayRecipeData(recipeData);
          dispatch(setRecipeDetail(recipeData));
        })
        .catch((reason: ApiError) => {
          apiErrorHandler(reason, () => {
            if (reason.status === 404) {
              setDisplayRecipeData(undefined);
            }
          });
        });
    } else {
      setDisplayRecipeData(undefined);
      // TODO: dispatch clearRecipeDetail here
    }
  }, [apiErrorHandler, dispatch, displayRecipeName]);

  return (
    <RecipeViewLayoutContainer>
      <AutocompleteTextField
        options={recipeNameList}
        label="recipe name"
        onChange={(_: SyntheticEvent, newValue: string | null) => {
          setDisplayRecipeName(newValue !== null ? newValue : "");
        }}
      />
      {/* <CommonButton text="Get" primary="true" />*/}

      <Outlet />

      <FormGroup>
        <FormControlLabel
          control={
            <YesNoSwitch
              checked={showDebugJson}
              onChange={handleChangeShowJson}
            />
          }
          label="Debug JSON"
        />
      </FormGroup>
      {showDebugJson ? (
        <pre>{JSON.stringify(displayRecipeData, undefined, 2)}</pre>
      ) : null}
    </RecipeViewLayoutContainer>
  );
};

export default RecipeViewLayout;
