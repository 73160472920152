/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DishOrder } from '../models/DishOrder';
import type { GroupedPrepInstructions } from '../models/GroupedPrepInstructions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PreparingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Prep Instructions
   * Get the preparing instructions for all recipes in dish orders
   *
   * Args:
   * dish_orders: The dish batch orders (not customer dish orders by portions)
   * show_dish_order_summary_table: Whether to print dish order summary table in log
   *
   * Returns:
   * The preparing instruction grouped by robot, oven, hub, and outsourced recipe operation types
   * @param requestBody
   * @param showDishOrderSummaryTable
   * @returns GroupedPrepInstructions Successful Response
   * @throws ApiError
   */
  public getPrepInstructionsGetPrepInstructionsPost(
    requestBody: Array<DishOrder>,
    showDishOrderSummaryTable: boolean = true,
  ): CancelablePromise<GroupedPrepInstructions> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/get_prep_instructions',
      query: {
        'show_dish_order_summary_table': showDishOrderSummaryTable,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not found`,
        422: `Validation Error`,
      },
    });
  }

}
