import React, { FC, MouseEvent, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavItemProps, StyledNavButton } from "components/navBar/ui/NavButton";
import { useTranslation } from "react-i18next";

export interface NavExpandingMenuProps {
  menuDisplayName: string;
  options: Array<NavItemProps>;
}

const StyledMenuItem = styled(MenuItem)`
  text-align: center;
  color: black;

  &:hover {
    color: #ffcf84;
  }
` as typeof MenuItem;

const NavExpandingMenu: FC<NavExpandingMenuProps> = ({
  menuDisplayName,
  options,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledNavButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {menuDisplayName}
      </StyledNavButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((item) => (
          <StyledMenuItem
            key={item.path}
            onClick={handleClose}
            component={Link}
            to={item.path}
          >
            {t(item.displayName)}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NavExpandingMenu;
