import styled from "styled-components";
import Box from "@mui/material/Box";

const DataGridListBox = styled(Box)`
  width: 100%;
  height: 500px;

  & .actions {
    color: rgb(120, 203, 255);
  }

  & .textPrimary {
    color: grey;
  }

  & .danger {
    color: rgb(246, 97, 97);
  }
`;

export default DataGridListBox;
