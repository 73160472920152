import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecipeFrontEndTransaction } from "client/blueChef";

interface RecipeDetailStateProps {
  /** Return value of recipe data from the Blue Chef API call */
  recipe: RecipeFrontEndTransaction | null;
}

const initialState: RecipeDetailStateProps = { recipe: null };

export const recipeDetailSlice = createSlice({
  name: "recipeDetailSlice",
  initialState,
  reducers: {
    setRecipeDetail: (
      state,
      action: PayloadAction<RecipeFrontEndTransaction | null>,
    ) => {
      return { ...state, recipe: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRecipeDetail } = recipeDetailSlice.actions;

export default recipeDetailSlice.reducer;
