import { FC } from "react";
import styled from "styled-components";
import { Container } from "@mui/material";
import { useAppSelector } from "hooks/useReduxStore";
import NutrientRow from "components/food/ui/NutritientRow";
import nutritionSelector from "store/selectors/recipe/nutritionSelector";

interface NutritionContainerProps {}

interface DividingLineProps {
  thickness?: number;
}

const NutritionPageContainer = styled(Container)`
  padding: 20px 0;
`;

const NutritionLabelContainer = styled.div`
  max-width: 340px;
`;

const NutritionLabel = styled.div`
  border: 2px solid black;
  padding: 5px;
  position: relative;
`;

const NutritionTitle = styled.div`
  font-size: 20pt;
  font-weight: 600;
`;

const NutritionSubtitle = styled.div`
  font-size: 14pt;
  font-weight: 400;
`;

const NutritionLabelInlineExtraInfo = styled.div`
  display: inline;
  position: relative;
  right: 0;
  text-align: right;
  font-weight: bold;
  font-size: 10pt;
  //padding: 2px;
`;

const NutritionLabelFooterExtraInfo = styled.div`
  font-size: 9pt;
  padding: 2px 1px 0 1px;
  & .bold {
    font-weight: 900;
    display: inline;
  }
`;

const NutritionSourceText = styled.div`
  text-align: center;
  font-size: 9pt;
  font-weight: 300;
  padding: 2px;
`;

const DividingLine = styled.div<DividingLineProps>`
  flex: 1;
  border-top: ${({ thickness }) => thickness || 2}px solid black;
`;

const NutritionContainer: FC<NutritionContainerProps> = () => {
  const nutritionRecords = useAppSelector(nutritionSelector);

  return (
    <NutritionPageContainer>
      {nutritionRecords?.map((record) => {
        return (
          <NutritionLabelContainer key={record.nutrition_id}>
            <NutritionLabel>
              <NutritionTitle>Nutrition Facts</NutritionTitle>
              <NutritionTitle>Valeur nutritive</NutritionTitle>
              <NutritionSubtitle>
                Per 1 portion ({Math.round(record.serving_size)} ml/g)
              </NutritionSubtitle>
              <NutritionSubtitle>
                pour 1 portion ({Math.round(record.serving_size)} ml/g)
              </NutritionSubtitle>
              <DividingLine />
              <div>
                <NutrientRow
                  nutrientName="Calories"
                  value={record.calories}
                  primary
                  fontSize={18}
                />
                <NutritionLabelInlineExtraInfo>
                  <div>% daily value*</div>
                  <div>% valeur quotidienne*</div>
                </NutritionLabelInlineExtraInfo>
              </div>
              <NutrientRow
                nutrientName="Fat / Lipides"
                value={record.fat}
                valueUnit="g"
                primary
                displayDailyValue
              />
              <NutrientRow
                nutrientName="Saturated / saturés"
                value={record.saturated_fat}
                valueUnit="g"
                subCategory
              />
              <NutrientRow
                nutrientName="+ Trans / trans"
                value={record.trans_fat}
                valueUnit="g"
                subCategory
              />
              <NutrientRow
                nutrientName="Carbohydrates / Glucides"
                value={record.carbohydrates}
                valueUnit="g"
                primary
              />
              <NutrientRow
                nutrientName="Fibre / Fibres"
                value={record.fibre}
                valueUnit="g"
                subCategory
                displayDailyValue
              />
              <NutrientRow
                nutrientName="Sugars / Sucres"
                value={record.sugars}
                valueUnit="g"
                subCategory
                displayDailyValue
              />
              <NutrientRow
                nutrientName="Protein / Protéines"
                value={record.protein}
                valueUnit="g"
                primary
              />
              <NutrientRow
                nutrientName="Cholesterol / Cholestérol"
                value={record.cholesterol}
                valueUnit="mg"
                primary
              />
              <NutrientRow
                nutrientName="Sodium"
                value={record.sodium}
                valueUnit="mg"
                primary
                displayDailyValue
              />
              <DividingLine thickness={4} />
              <NutrientRow
                nutrientName="Potassium"
                value={record.potassium}
                valueUnit="mg"
                displayDailyValue
              />
              <NutrientRow
                nutrientName="Calcium"
                value={record.calcium}
                valueUnit="mg"
                displayDailyValue
              />
              <NutrientRow
                nutrientName="Iron / Fer"
                value={record.iron}
                valueUnit="mg"
                displayDailyValue
              />
              <DividingLine thickness={5} />
              <NutritionLabelFooterExtraInfo>
                *5% or less is <div className="bold">a little</div>, 15% or more
                is <div className="bold">a lot</div>
              </NutritionLabelFooterExtraInfo>
              <NutritionLabelFooterExtraInfo>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                *5% ou moins c'est <div className="bold">peu</div>, 15% ou plus
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                c'est <div className="bold">beaucoup</div>
              </NutritionLabelFooterExtraInfo>
            </NutritionLabel>
            <NutritionSourceText>
              Source: {record.data_source}
            </NutritionSourceText>
          </NutritionLabelContainer>
        );
      })}
    </NutritionPageContainer>
  );
};

export default NutritionContainer;
