import { Grid } from "@mui/material";
import React, { FC } from "react";
import { FoodItemSupplierInfo } from "client/jspPlatformExperiment";
import { useAppSelector } from "hooks/useReduxStore";
import foodItemDetailSelector from "store/selectors/foodItem/foodItemDetailSelector";
import SpacedTextField from "components/common/ui/SpacedTextField";

interface FoodSupplierViewSectionProps {}

export const supplierFields: {
  label: string;
  name: keyof FoodItemSupplierInfo;
}[] = [
  { label: "Supplier Name", name: "supplier_id" },
  { label: "Product Code", name: "product_code" },
  { label: "Product Name", name: "product_name" },
  { label: "Min Format", name: "min_format" },
  { label: "Unit Size", name: "unit_size" },
  { label: "Unit Price", name: "unit_price" },
  // { label: "Retail Price", name: "retail_price" },
  { label: "URL", name: "url" },
];

const FoodSupplierViewSection: FC<FoodSupplierViewSectionProps> = () => {
  const supplierRecords = useAppSelector(foodItemDetailSelector.foodSuppliers);
  const isLoading = useAppSelector(foodItemDetailSelector.foodIsLoading);
  const supplierInfo = supplierRecords?.at(0);

  return isLoading || !supplierInfo ? (
    <div>Loading...</div>
  ) : (
    <Grid container spacing={1} columns={24}>
      {supplierFields.map((supplierTextFieldProps) => (
        <Grid
          item
          key={supplierTextFieldProps.name}
          xs={supplierTextFieldProps.name !== "url" ? 12 : 24}
          md={supplierTextFieldProps.name !== "url" ? 4 : 24}
          lg={supplierTextFieldProps.name !== "url" ? 3 : 6}
        >
          <SpacedTextField
            InputLabelProps={{ shrink: true }}
            label={supplierTextFieldProps.label}
            name={supplierTextFieldProps.name}
            value={supplierInfo[supplierTextFieldProps.name] ?? ""}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodSupplierViewSection;
