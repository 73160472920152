import { Grid, InputAdornment } from "@mui/material";
import React, { FC } from "react";
import { useAppSelector } from "hooks/useReduxStore";
import foodItemDetailSelector from "store/selectors/foodItem/foodItemDetailSelector";
import SpacedTextField from "components/common/ui/SpacedTextField";

export interface FoodItemViewSectionProps {}

const FoodItemBasicInfoViewSection: FC<FoodItemViewSectionProps> = () => {
  const displayingFoodItemBasicData = useAppSelector(
    foodItemDetailSelector.foodItemBasic,
  );
  const isLoading = useAppSelector(foodItemDetailSelector.foodIsLoading);

  return isLoading || !displayingFoodItemBasicData ? (
    <div>Loading...</div>
  ) : (
    <Grid container spacing={1} columns={24}>
      <Grid item xs={24} sm={18} md={24} lg={12}>
        <SpacedTextField
          label="Name"
          value={displayingFoodItemBasicData.food_name}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <SpacedTextField
          label="State"
          value={displayingFoodItemBasicData.state}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <SpacedTextField
          label="Category"
          value={displayingFoodItemBasicData.category}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SpacedTextField
          label="Store Temperature"
          value={displayingFoodItemBasicData.storage_temp}
        />
      </Grid>
      <Grid item xs={24} sm={12} md={6} lg={3}>
        <SpacedTextField
          label="Portion Size"
          value={displayingFoodItemBasicData.portion_size ?? "N/A"}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FoodItemBasicInfoViewSection;
